import React, { useEffect, useState, useCallback } from "react";
import { getOfferings } from "../shared/merchant";
import ProductCard from "../components/ProductCard";
import BundleCard from "../components/BundleCard";

import strings from '../strings.json';
import {debounce} from "../shared/utils";
import {product, price, metadata} from "../App";


const enterpriseFeatures = [
  "Access to cloud-based photogrammetry models anytime, anywhere",
  "Global floating seats",
  "Stratbox Cloud Service",
  "Dedicated Key account manager",
  "Product activation engagement",
  "Customer onboarding engagement",
  "Expedited Technical support",
  "Access to cloud-based 3D model datasets for use with Stratbox Connect (Desktop). Please contact us for further information and pricing"
]
const productFeatures = {
  "con": [
    "Available for Windows & Mac",
    "Interpretation tools",
    "Data integration in 3D space",
    "Access to satellite imagery",
    "Remote collaboration",
    "Scene creation",
    "Friendly user interface",
    "Email support",
    "Access to cloud-based 3D model datasets. Please contact us for further information and pricing"
  ],
  "con_vr": [
    "Available on PC-VR, Quest and Focus3",
    "Virtual reality",
    "Data integration in 3D space",
    "Access to satellite imagery",
    "Interpretation tools",
    "Remote collaboration",
    "VR scene creation (multimedia support)",
    "Immersion",
    "Spectator camera",
    "Friendly user interface",
    "Email support"
  ],
  "ana": [
    "Available for Windows desktop",
    "Import georeferenced or local models",
    "Interpretation tools",
    "Project/overlay images on the 3D outcrop models",
    "Reference models on satellite imagery",
    "Layering system",
    "Multi-model scene",
    "Import ground control points",
    "Export interpretations as shape files",
    "Export measurements for dip, strike, true later thickness",
    "Python API",
    "Friendly user interface",
    "Email support"
  ],
  "ce": [
    "Customisable log panel",
    "Core store table",
    "Multi-well comparison",
    "Automated data integration",
    "Interpretation tools",
    "Annotations",
    "On-demand access",
    "Remote collaboration in real time",
    "Python API for machine learning",
    "Friendly user interface",
    "Email support"
  ]
}
// interface product {
//   id: string,
//   object: string,
//   active: boolean,
//   attributes: any[],
//   created: number,
//   default_price: string,
//   description: string,
//   features: any[],
//   images: string[],
//   livemode: boolean,
//   metadata: metadata,
//   name: string,
//   package_dimensions: any,
//   shippable: any,
//   statement_descriptor: any,
//   tax_code: string,
//   type: string,
//   unit_label: any,
//   updated: number,
//   url: any
//   prices: price[];
// }

// interface metadata {
//   type: string
// }

// interface price {
//   id: string,
//   object: string,
//   active: boolean,
//   billing_scheme: string,
//   created: number,
//   currency: string,
//   custom_unit_amount: any,
//   livemode: boolean,
//   lookup_key: any,
//   metadata: object,
//   nickname: any,
//   product: any,
//   recurring: {
//     aggregate_usage: any,
//     interval: any,
//     interval_count: number,
//     trial_period_days: any,
//     usage_type: string
//   },
//   tax_behavior: string,
//   tiers_mode: any,
//   transform_quantity: any,
//   type: string,
//   unit_amount: number,
//   unit_amount_decimal: string
// }

const colors = [
  "#9e7bcc",
  "#f9c711",
  "#d1c0e8"
]

const productOrder = [
  'Stratbox Connect',
  'Stratbox Connect VR',
  'Stratbox Analytics',
  'Stratbox Core Explorer'
];

type CustomerType = "business" | "academia";



const Plans = (props: {
  offers: product[],
  cartOpen: boolean,
  setCartOpen: any,
  isEdu: boolean,
  userDetails: any,
  setOfferings: any,
}) => {

  // const [offers, setOfferings] = useState<product[]>([]);
  const [selectedCustomerType, setCustomerType] = useState('business');
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [interval, setInterval] = useState('month');
  const [showToggle, setShowToggle] = useState(false);

  useEffect(() => {
    document.title = 'Plans | Stratbox';

    const queryString = window.location.search;
    console.log(queryString);

    const urlParams = new URLSearchParams(queryString);
    console.log(urlParams);

    const customer_type = urlParams.get('customer_type');
    console.log(customer_type);

    if (customer_type) {
      setCustomerType(customer_type);
    };


  }, []);

  // If debounce relies on props or state, use useCallback to memoize it
  const debouncedHandleResize = useCallback(debounce(() => {
    setInnerWidth(window.innerWidth);
  }, 100), []);

  useEffect(() => {
    console.log("UE: []");

    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [debouncedHandleResize]);


  // useEffect(()=>{
  //   try {
  //     getOfferings().then((res) => (res.json())
  //       .then((data) => {
  //         console.log(data);
  //         setOfferings(data.offerings);
  //         props.setOfferings(data.offerings)
  //       })
  //     );
  //
  //     console.log(offers);
  //   }
  //   catch (e) {
  //     console.log("ERROR Fetching offerings");
  //     console.log(e);
  //
  //     setOfferings([]);
  //   }
  // }, []);


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: strings.colors.backgroundSecondary,
        // height: "100%",
        flexGrow: "2",
        // marginRight: props.cartOpen? "22rem" : "auto",
        // maxWidth: "60rem",
        // width: "100%",
        width: props.cartOpen? `calc(${window.innerWidth}px - 22rem)` : "100%",
        // margin: "auto",
        // flexGrow: "1",
        // padding: "0rem 2rem",
        opacity: props.cartOpen? 0.35 : 1,
        cursor: props.cartOpen? "pointer" : "default",
        paddingBottom: "1rem",

      }}
      onClick={() => {
        console.log("setexpanded");
        props.cartOpen && props.setCartOpen(false)
      }}
    >

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // paddingTop: "2rem",
          width: "100%",
          maxWidth: "1200px",
          // flexGrow: "1",
          // border: "solid 0.065rem black",
          backgroundColor: strings.colors.backgroundSecondary,
          // padding: "1rem",
        }}
      >

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "2rem",
            paddingLeft: "4.5rem",
            paddingRight: "4.5rem",
            // border: "solid 1px black",
          }}
        >

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "2rem",
              // paddingLeft: "4.5rem",
              // paddingRight: "4.5rem",
              // border: "solid 1px black",
            }}
          >

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                // width: "100%",
                // marginTop: "2rem",
                fontSize: strings.sizes.iconSecondary,
                color: strings.colors.textPrimary,
                fontWeight: strings.weights.medium,
                // paddingLeft: "4.5rem",
                // border: "solid 1px lightseagreen",
              }}
              onClick={() => setShowToggle(!showToggle)}
            >
              Applications
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",

              }}
            >

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  // justifyContent: "center",
                  alignItems: "flex-start",
                  minWidth: "18.5rem",
                  width: "18.5rem",
                  // border: "solid 1px lightseagreen",
                  marginBottom: "0.25rem",
                }}
              >

                {/*LABEL*/}
                <div
                  style={{
                    fontSize: strings.sizes.iconSecondary,
                    lineHeight: strings.sizes.iconSecondary,
                    color: strings.colors.textPrimary,
                    fontWeight: strings.weights.regular,
                  }}
                >
                  License type
                </div>

                {/*DROPDOWN*/}
                <div
                  className={"custom-select"}
                  style={{
                    // width: "200px",
                    transform: "translateY(-0.75rem)",
                  }}
                >

                  <select
                    name={"customerType"}
                    id={"customerType"}
                    defaultValue={"business"}
                    value={selectedCustomerType}
                    style={{
                      marginLeft: "1.25rem",
                      fontSize: strings.sizes.iconSecondary,
                      fontWeight: strings.weights.regular,
                      color: strings.colors.textTertiary,
                      border: "none",
                      borderRadius: "0.35rem",
                      padding: "0.5rem  2rem 0.5rem 1rem",
                      boxShadow: "1px 1px 5px 0px rgb(0 0 0 / 10%)",
                      cursor: "pointer"
                    }}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setCustomerType(e.target.value as CustomerType);
                    }}
                  >
                    <option
                      style={{
                        userSelect: "none"
                      }}
                      value={"business"}
                    >Business
                    </option>

                    <option
                      style={{
                        userSelect: "none"
                      }}
                      value={"academia"}
                    >Academia
                    </option>

                  </select>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginBottom: "0.5rem",
                }}
              >


              <div
                style={{
                  fontSize: "1rem",
                  lineHeight: "1rem",
                  color: strings.colors.textPrimary,
                  fontWeight: strings.weights.regular,
                }}
              >To enquire about Enterprise pricing and schedule a demo -
              </div>
                <a
                  href={"https://www.imagedreality.com/contact/"} target="blank"
                  style={{
                    fontSize: "1rem",
                    lineHeight: "1rem",
                    fontWeight: strings.weights.regular,
                    color: strings.colors.actionNeutral,
                    cursor: "pointer",
                    userSelect: "none",
                    textDecoration: "none"
                  }}
                  >
                    &nbsp;get in touch
                </a>
              </div>


            </div>


          </div>

        </div>


        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            width: "100%",
            maxWidth: "1080px",
            // border: "solid 1px black",
            flexGrow: "1",
            flexWrap: "wrap",
          }}
        >

          { props.offers.length > 0 && productOrder.map((productName, index) => {
            return (
              <ProductCard
                userDetails={props.userDetails}
                isEdu={props.isEdu}
                isBiz={selectedCustomerType === "business"}
                index={index}
                key={index}
                interval={interval}
                offer={props.offers.find((offer: product) => offer.name == productName && offer.metadata.type === selectedCustomerType)}
              />
            )
          })}
        </div>


        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            marginTop: "1rem",
            fontSize: strings.sizes.iconSecondary,
            color: strings.colors.textPrimary,
            fontWeight: strings.weights.medium,
            paddingLeft: "4.5rem",
          }}
        >
          Bundles
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            width: "100%",
            maxWidth: "1080px",
            flexGrow: "1",
            flexWrap: "wrap",
            // border: "solid 0.065rem black"
          }}
        >

              {
                props.offers
                  .filter(offer =>
                    offer.metadata.type === selectedCustomerType &&
                    offer.name.toLowerCase().includes("+")
                  )
                  .map((offer, index) => (

                    // innerWidth > 836?

                      <BundleCard
                        userDetails={props.userDetails}
                        isEdu={props.isEdu}
                        index={index}
                        isBiz={selectedCustomerType === "business"}
                        key={index}
                        offer={offer}
                        interval={interval}
                      />
                      // :
                      // <ProductCard
                      //   userDetails={props.userDetails}
                      //   isEdu={props.isEdu}
                      //   isBiz={selectedCustomerType === "business"}
                      //   index={index}
                      //   key={index}
                      //   offer={offer}
                      // />


                  ))
              }

          </div>

      </div>


    </div>
  );
}

export default Plans;