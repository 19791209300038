
import strings from "../strings.json";

const SignupSuccess = () => {

    return (
        <section id="">
            <div
                style={{
                    marginTop: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: strings.weights.medium,
                    color: strings.colors.textPrimary,
                    fontSize: strings.sizes.textPrimary,
                }}
            >
                Success. Please check your inbox for the verification email.
            </div>
        </section>
    );

}

export default SignupSuccess;