import React, {useEffect, useRef, useState} from "react";
import { createCheckoutSession } from "../shared/merchant";
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import {useNavigate} from "react-router-dom";
import strings from "../strings.json";
import {agreeTerms} from "../shared/utils";

const stripe_pk: string = "pk_live_51ONDFZBu6k6cGFaYOzbWUVTMS4i4BA5PPQucTubVILjVGR6PEbi1mJjwJyoZG6txQcvfLP8riLwLi3qqXdLvYp7e00y4IOjaAk";


// Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripe_pk);

interface lineItem {
    price: string,
    quantity: number,
}

const Checkout = (props: any) => {

    const navigate = useNavigate();

    const [cart, setCart] = useState(localStorage.getItem("cart"));
    const [clientSecret, setClientSecret] = useState('');
    const [hasAgreedTerms, setHasAgreedTerms] = useState(false);
    const [displayCheckout, setDisplayCheckout] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(()=>{
        document.title = 'Checkout | Stratbox';
    }, []);

    useEffect(()=>{
        if (props.userDetails === undefined || props.userDetails === null) {
            // setTimeout(() => {
            //     console.log("checkout.tsx redirect to /login")
            //
            //     navigate("/plans", { replace: true });
            // }, 500);

            console.log("checkout.tsx redirect to /login")

            navigate("/plans", { replace: true });

        }
    }, [props.userDetails]);

    useEffect(() => {


        if (props.userDetails === undefined || props.userDetails === null) {
            // setTimeout(() => {
            //     console.log("checkout.tsx redirect to /login")
            //
            //     navigate("/plans", { replace: true });
            // }, 500);

            console.log("checkout.tsx redirect to /login")

            navigate("/plans", { replace: true });

        }

        else {

            let customerType: string = "";

            if (cart !== null) {

                let parsedCart: { [key: string]: { quantity: number, customerType: string } } = JSON.parse(cart); // Specify the type of parsedCart

                console.log(parsedCart);

                let lineItems: lineItem[] = [];

                Object.entries(parsedCart).forEach(([cartItemKey, item]) => {

                    console.log(cartItemKey);
                    console.log(item);

                    customerType = item.customerType;

                    lineItems.push({
                        price: cartItemKey,
                        quantity: item.quantity,
                    });
                });

                if (Object.entries(parsedCart).length === 0) {
                    console.log("checkout.tsx redirect to /plans")

                    navigate("/plans", {replace: true});
                } else if (props.userDetails.role !== "admin") {
                    window.alert("Please contact your administrator to request new subscriptions, or create a new account with your personal email to make an individual purchase.");

                    setTimeout(() => {
                        console.log("verify.tsx redirect to /login?cart=true")

                        navigate("/plans");

                    }, 500)
                } else {
                    createCheckoutSession(lineItems, props.userDetails.email, customerType)
                      .then((res) => (res.json())
                        .then((data) => setClientSecret(data.clientSecret))
                      );
                }

            }
        }

    }, []);


    if (!displayCheckout) {
        return (

          <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                // backgroundColor: strings.colors.backgroundSecondary,
                flexGrow: "2",
                paddingBottom: "1rem",
            }}
          >

            {/*NAV ROW*/}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "1rem",
                marginBottom: "1rem",
                width: "100%",
              }}

            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  cursor: "pointer",
                  marginLeft: "3rem"
                }}
                onClick={() => navigate(`/plans`, {replace: true} )}
              >
                <div
                  className={'material-icons'}
                  style={{
                    opacity: "1",
                    fontSize: "1.375rem",
                    lineHeight: "1.375rem",
                    textDecoration: "none",
                    WebkitTouchCallout: "none",
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    msUserSelect: "none",
                    userSelect: "none",
                    color: strings.colors.action,
                  }}
                >
                  arrow_back
                </div>

                <div
                  style={{
                    fontSize: strings.sizes.iconSecondary,
                    lineHeight: strings.sizes.iconSecondary,
                    color: strings.colors.textPrimary,
                    fontWeight: strings.weights.regular,
                  }}
                >
                  &nbsp;back to products
                </div>
              </div>
            </div>

              <div
                className="sc-login p-4"
                style={{marginTop: "4rem"}}
              >

                  {/* TITLE */}
                  <div
                    className="login-title"

                    style={{
                        fontSize: "1rem",
                        fontWeight: strings.weights.medium,
                        color: strings.colors.textPrimary,
                    }}
                  >
                      Confirmation
                  </div>

                  <div className=" mb-3"></div>

                  <div
                    id="agree-terms-field"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        userSelect: "none",
                        marginBottom: "1rem",
                        cursor: "pointer",
                        flexWrap: "wrap",

                    }}

                  >
                      {/*TERMS CHECKBOX*/}
                      <input
                        type="checkbox"
                        id="agreeTerms"
                        className={`text-09`}
                        name="agreeStoreTerms"
                        onChange={ async (e) => {

                          try {
                            setLoading(true);
                            let response = await agreeTerms(props.userDetails.email);

                            console.log(response);

                            if (response.payload == true) {
                              console.log("success");
                              setHasAgreedTerms(true);
                              setLoading(false);
                            }
                            else {
                              setLoading(false);
                              window.alert("There was an error recording your response. Please try again, or reach out to Imaged Reality for support");
                            }
                          }

                          catch (e) {
                            console.log(e);
                            setLoading(false);
                            window.alert("There was an error recording your response. Please try again, or reach out to Imaged Reality for support");
                          }

                        }}
                        checked={hasAgreedTerms}
                      />

                      <div
                        style={{
                            fontSize: strings.sizes.textSecondary,
                            color: strings.colors.textPrimary,
                            fontWeight: strings.weights.medium,
                        }}
                        onClick={async (e) => {

                          try {

                            setLoading(true);
                            let response = await agreeTerms(props.userDetails.email);

                            console.log(response);

                            if (response.payload == true) {
                              console.log("success");
                              setHasAgreedTerms(true);
                              setLoading(false);
                            }
                            else {
                              setLoading(false);
                              window.alert("There was an error recording your response. Please try again, or reach out to Imaged Reality for support");
                            }
                          }

                          catch (e) {
                            console.log(e);
                            setLoading(false);
                            window.alert("There was an error recording your response. Please try again, or reach out to Imaged Reality for support");
                          }

                        }}
                      >
                          &nbsp;&nbsp;By clicking the "Proceed" button you agree to&nbsp;
                      </div>
                      <a
                        href={"https://www.imagedreality.com/terms-and-conditions/"}
                        target={"none"}
                        style={{
                            fontSize: strings.sizes.textSecondary,
                            fontWeight: strings.weights.medium,
                            marginTop: "0.75rem",
                        }}
                      >
                          Terms & Conditions
                      </a>
                  </div>

                  <div className=" mb-3"></div>


                  <button
                    type="submit"
                    id="proceed-submit"
                    className="btn btn-stbx-primary form-control"
                    disabled={!hasAgreedTerms}
                    style={{
                      opacity: hasAgreedTerms ? 1 : 0.35
                    }}
                    onClick={(e) => {
                      setDisplayCheckout(true);
                    }}
                  >
                    {loading?
                      <div
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                      />
                      :
                      <span>Proceed</span>
                    }
                  </button>

                  <div className=" mb-3"></div>
              </div>
          </div>
        )
    }
        else {

    return (


      <div
        style={{
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "flex-start",
            // alignItems: "center",
            // backgroundColor: strings.colors.backgroundSecondary,
            flexGrow: "2",
            paddingBottom: "1rem",
        }}
    >
        {/*NAV ROW*/}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "1rem",
            marginBottom: "1rem",

            width: "100%",
          }}

        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer",
              marginLeft: "3rem",
            }}
            onClick={() => navigate(`/plans`, {replace: true} )}
          >
            <div
              className={'material-icons'}
              style={{
                opacity: "1",
                fontSize: "1.375rem",
                lineHeight: "1.375rem",
                textDecoration: "none",
                WebkitTouchCallout: "none",
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                msUserSelect: "none",
                userSelect: "none",
                color: strings.colors.action,
              }}
            >
              arrow_back
            </div>

            <div
              style={{
                fontSize: strings.sizes.iconSecondary,
                lineHeight: strings.sizes.iconSecondary,
                color: strings.colors.textPrimary,
                fontWeight: strings.weights.regular,
              }}
            >
              &nbsp;back to products
            </div>
          </div>
        </div>

            <div
              id="checkout"
              style={{
                  opacity: displayCheckout? 1 : 0.35
              }}
            >
                {clientSecret !== '' && displayCheckout && (
                    <EmbeddedCheckoutProvider
                        stripe={stripePromise}
                        options={{clientSecret}}
                    >
                        <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                )}
            </div>
      </div>
    )
          }
}


export default Checkout;