import React, { useEffect, useState } from "react";
import {Link, Navigate, useNavigate, useSearchParams} from 'react-router-dom';
import { login, getSession, FetchResult, getUser, getSubscriptions } from '../shared/authentication';
import { redirectToUri, validateEmail } from '../shared/utils';


import "../bootstrap-icons.svg";
import "../bootstrap.css";

import strings from "../strings.json";

function Login (props: any){

    // const [userDetails, setUserDetails] = useState();
    const [subscriptionDetails, setSubscriptionDetails] = useState();
    const [tokensVerified, setTokensVerified] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams ();
    const [formData, setFormData] = useState(
        {
            email:"",
            emailFeedback:"",
            emailValid:false,
            password:"",
            passwordFeedback:"Please enter your password.",
            passwordValid:false,
            formValidated:false,
            isLoggingIn:false,
            loginFailed:false,
            loginFeedback:"",
            tokensVerified:false,
            passwordVisible:false
        }
    );

    const navigate = useNavigate();

    useEffect(()=>{
        document.title = 'Log in | Stratbox';
    }, []);


    useEffect(()=>{
        if (props.userDetails !== undefined && props.userDetails !== null) {

            if (searchParams.get('cart') === "true")
            {

                setTimeout(() => {
                    console.log("login.tsx redirect to /checkout")

                    navigate("/checkout", { replace: true });
                }, 100)
            }
            else {
                setTimeout(() => {
                    console.log("login.tsx redirect to /plans")

                    navigate("/plans", { replace: true });
                }, 100);
            }

        }
    }, [props.userDetails]);


    function handleChange(event: React.ChangeEvent<HTMLInputElement>){
        console.log("handleChange");

        setFormData(prevFormData=>{
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    function validateForm(callback: Function){
        var emailFeedback = "Please enter your email.";
        var emailValid = true;

        // if( formData.email.length > 0){
        //     emailValid = validateEmail(formData.email);
        //     if(!emailValid) emailFeedback = "Please enter a valid email.";
        // }

        var passwordValid = formData.password.length > 0;

        setFormData(prevFormData=>{
            return {
                ...prevFormData,
                formValidated: true,
                emailValid: emailValid,
                emailFeedback: emailFeedback,
                passwordValid: passwordValid,
            }
        });

        callback(emailValid && passwordValid);
    }

    function showPassword(){
        setFormData(prevFormData=>{
            return {
                ...prevFormData,
                passwordVisible: true,
            }
        });
    }

    function hidePassword(){
        setFormData(prevFormData=>{
            return {
                ...prevFormData,
                passwordVisible: false,
            }
        });
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault();
        if(formData.isLoggingIn)return;
        validateForm(async (success: boolean)=>{
            if(success){
                setFormData(prevFormData=>{
                    return {
                        ...prevFormData,
                        isLoggingIn: true,
                        loginFailed:false,
                    }
                });

                const {error,payload} = await login(formData.email, formData.password);

                if(error){
                    setFormData(prevFormData=>{
                        return {
                            ...prevFormData,
                            isLoggingIn: false,
                            loginFailed: true,
                            loginFeedback: error
                        }
                    });
                }

                else {
                    console.log(payload);

                    props.setUserDetails(payload.user);
                    setTokensVerified(true);
                }
            }
        })
    }

    function renderLoginForm(){
        return(
            <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  backgroundColor: strings.colors.backgroundSecondary,
                  flexGrow: "2",
                  // width: props.cartOpen? `calc(${window.innerWidth}px - 22rem)` : "100%",
                  // opacity: props.cartOpen? 0.35 : 1,
                  // cursor: props.cartOpen? "pointer" : "default",
                  paddingBottom: "1rem",
                }}
            >
                <div
                    style={{
                        marginTop: "4rem",
                    }}
                >
                    <section className="sc-login p-4">
                        <div
                          className="login-title"
                          style={{
                              fontSize: "1rem",
                              fontWeight: strings.weights.medium,
                              color: strings.colors.textPrimary,
                          }}
                        >Log in to your account</div>
                        {formData.loginFailed &&
                            <div className="alert alert-danger mt-3" role="alert">
                                {formData.loginFeedback}
                            </div>
                        }
                        <form id="form-login" onSubmit={handleSubmit} noValidate>
                            <div id="email-field">
                                <input
                                    type="email"
                                    placeholder="Enter email"
                                    id="email"
                                    className={`form-control ${(formData.formValidated && !formData.emailValid)?"is-invalid":""} text-09`}
                                    name="email"
                                    onChange={handleChange}
                                    value={formData.email}
                                />
                                <div className="invalid-feedback">
                                    {formData.emailFeedback}
                                </div>
                                <div className=" mb-3"></div>
                            </div>

                            <div
                                id="password-field"
                            >
                                <div
                                    className="input-group"
                                >
                                    <input
                                        type={formData.passwordVisible?"text":"password"}
                                        placeholder="Enter password"
                                        id="password"
                                        className={`form-control ${(formData.formValidated && !formData.passwordValid)?"is-invalid":""} text-09`}
                                        name="password"
                                        onChange={handleChange}
                                        value={formData.password}
                                    />

                                    <div
                                        onMouseDown={showPassword}
                                        onMouseUp={hidePassword}
                                        onMouseLeave={hidePassword}
                                        className={'material-icons password-visibility'}
                                        style={{
                                            width : "2.5rem",
                                            display: "flex",
                                            justifyContent: "center",
                                            color: strings.colors.textTertiary,
                                            fontSize: "1.375rem",
                                            lineHeight: "1.375rem",
                                            textDecoration: "none",
                                            WebkitTouchCallout: "none",
                                            WebkitUserSelect: "none",
                                            MozUserSelect: "none",
                                            msUserSelect: "none",
                                            userSelect: "none",
                                            opacity: "0.65",
                                        }}
                                    >
                                        {formData.passwordVisible? "visibility_off" : "visibility"}
                                    </div>

                                </div>

                                <div className="invalid-feedback">
                                    {formData.passwordFeedback}
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginTop: "1rem",
                                    }}
                                >
                                    <a
                                        href="https://auth.stratbox.imagedreality.com/forgot-password"
                                        className="link"
                                    >
                                        Forgot your password?
                                    </a>

                                </div>

                                <div className=" mb-3"></div>
                            </div>

                            <div>
                                <button type="submit" id="login-submit" className="btn btn-stbx-primary form-control">
                                    {formData.isLoggingIn
                                        ? <div className="spinner-border spinner-border-sm text-light" role="status">
                                        </div>
                                        : <span>Log in</span>
                                    }
                                </button>

                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        color: strings.colors.textPrimary,
                                        fontSize: strings.sizes.textPrimary,
                                        margin: "1rem 0 0.5rem 0",
                                    }}
                                >
                                    Don't have an account?
                                </div>

                                <Link
                                    to="/signup"
                                    className="btn btn-stbx-primary form-control"
                                >
                                    Sign up
                                </Link>

                            </div>
                        </form>
                    </section>
                </div>
            </div>
        );
    }

    function renderVerifyTokens(){

        console.log("renderVerifyTokens");

        console.log("login.tsx [renderVerifyTokens()] redirect to /checkout")

        // navigate("/checkout", { replace: true });
        navigate("/plans", { replace: true });
    }

    return(
        <>
            {!tokensVerified? renderLoginForm() : renderVerifyTokens()}
        </>
    )
}

export default Login;