import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { signup } from "../shared/utils";
import React from "react";
import { validatePassword, validateEmail } from "../shared/utils";

import "../bootstrap-icons.svg";
import "../bootstrap.css";

import strings from "../strings.json";

function Signup (){

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams ();


    const [formData, setFormData] = React.useState(
        {
            email:"",
            emailValid: false,
            emailFeedback: "Please enter a valid email address",
            password:"",
            passwordValid:false,
            passwordFeedback:"Please enter your password.",
            confirmedPassword:"",
            confirmedPasswordValid:false,
            confirmedPasswordFeedback:"Please confirm your password.",
            formValidated:false,
            organisationName:"",
            organisationNameValid:false,
            organisationNameFeedback:"Please enter an organisation name.",
            firstName:"",
            firstNameValid:false,
            firstNameFeedback:"Please enter your first name.",
            lastName:"",
            lastNameValid:false,
            lastNameFeedback:"Please enter your last name.",
            isJoining:false,
            joinSucceeded:false,
            joinAttempted:false,
            joinFailedFeedback:"",
            agreeTerms: false,
            agreeTermsValid: false,
            agreeTermsFeedback: "Please accept the terms of service",
            newsletterConsent: false,
        }
    )

    useEffect( () => {
        document.title = 'Join | Stratbox';
    }, []);


    async function handleSubmit(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault();

        console.log("submit");

        if (formData.isJoining) return;

        if (validateForm()) {
            console.log("form is valid and ready to be sent!");

            setFormData(prevFormData=>{
                return {
                    ...prevFormData,
                    isJoining: true,
                    joinFailedFeedback:"",
                }
            });

            const result = await signup(formData.email,formData.organisationName,formData.firstName,formData.lastName, formData.password, formData.newsletterConsent);

            console.log(result);
            setFormData(prevFormData=>{
                return {
                    ...prevFormData,
                    isJoining: false,
                    joinAttempted:true,
                    joinSucceeded: result.success,
                    joinFailedFeedback: result.error
                }
            });

            if (result.error) {
                console.log(result.error);

            }


            if (result.success) {
                setTimeout(() => {
                    console.log("signup.tsx redirect to /signup_success");
                    navigate("/signup_success");
                }, 500);
            }

        }
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>){

        console.log(event);


        setFormData(prevFormData=>{

            if (event.target.name === "agreeTerms") {
                return {
                    ...prevFormData,
                    agreeTerms: event.target.checked
                }
            }

            if (event.target.name === "newsletterConsent") {
                return {
                    ...prevFormData,
                    newsletterConsent: event.target.checked
                }
            }

            else return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })

    }


    function validateForm(){

        console.log(formData);
        console.log(formData.agreeTerms);
        console.log(formData.newsletterConsent);

        let organisationNameValid = formData.organisationName.trim().length > 0;
        // let organisationNameValid = false;
        let emailValid = false;
        let emailFeedback = "";
        let firstNameValid = formData.firstName.trim().length > 0;
        let lastNameValid = formData.lastName.trim().length > 0;
        let passwordValid = false;
        let passwordFeedback = "Please enter a password.";
        let confirmedPasswordValid = false;
        let confirmedPasswordFeedback = "Please confirm your password.";
        let agreeTermsValid = formData.agreeTerms;
        let agreeTermsFeedback = "Please accept the terms of service.";
        let newsletterConsent = formData.newsletterConsent;



        if (formData.email.length > 0) {

            console.log("email length is okay");

            let emailCheckResult = validateEmail(formData.email);

            console.log(emailCheckResult);

            if (!emailCheckResult.success) {
                emailFeedback = emailCheckResult.message;
                emailValid = false;
            }
            else {
                emailValid = true;
            }
        }

        if(formData.password.length > 0){
            passwordValid = validatePassword(formData.password);
            if(!passwordValid) passwordFeedback = "You password must contain at least 8 characters (one digit, one upper case, one lower case and one special character, no spaces).";
        }

        if(formData.confirmedPassword.length > 0){
            confirmedPasswordValid = (formData.confirmedPassword === formData.password);
            if(!confirmedPasswordValid) confirmedPasswordFeedback = "Passwords must match";
        }

        if(!formData.agreeTermsValid){
            if(!confirmedPasswordValid) agreeTermsFeedback = "Please agree to terms";
        }


        setFormData(prevFormData=>{
            return {
                ...prevFormData,
                emailValid: emailValid,
                emailFeedback: emailFeedback,
                formValidated: true,
                organisationNameValid: organisationNameValid,
                firstNameValid: firstNameValid,
                lastNameValid: lastNameValid,
                passwordValid:passwordValid,
                passwordFeedback:passwordFeedback,
                confirmedPasswordValid:confirmedPasswordValid,
                confirmedPasswordFeedback:confirmedPasswordFeedback,
                agreeTermsValid: agreeTermsValid,
                agreeTermsFeedback: agreeTermsFeedback,
            }
        });

        return (emailValid && organisationNameValid && firstNameValid && lastNameValid && passwordValid && confirmedPasswordValid && agreeTermsValid);
    }

    return (

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: strings.colors.backgroundSecondary,
          flexGrow: "2",
          // width: props.cartOpen? `calc(${window.innerWidth}px - 22rem)` : "100%",
          // opacity: props.cartOpen? 0.35 : 1,
          // cursor: props.cartOpen? "pointer" : "default",
          paddingBottom: "1rem",
        }}
      >
        <div
            className="sc-login p-4"
            style={{
              marginTop: "4rem",
              // display: "flex",
              // flexGrow: "1",
              // height: "100%",
          }}
        >

            {/* TITLE */}
            <div
                className="login-title"
                style={{
                  fontSize: "1rem",
                  fontWeight: strings.weights.medium,
                  color: strings.colors.textPrimary,
                }}
            >
                Create an Imaged Reality account
            </div>

            <div className=" mb-3"></div>

            <form
                id="form-join"
                onSubmit={handleSubmit}
                noValidate
            >
                <div className="form-group">


                    {/* EMAIL */}
                    <div className=" mb-2"></div>
                    <div id="email-field">
                        <input
                            type="email"
                            id="email"
                            className={`form-control ${(formData.formValidated && !formData.emailValid)?"is-invalid":""} text-09`}
                            placeholder="Email"
                            name="email"
                            onChange={handleChange}
                            value={formData.email}
                        />
                        <div className="invalid-feedback">
                            {formData.emailFeedback}
                        </div>
                        <div className=" mb-3"></div>
                    </div>

                  {/* ORGANISATION */}
                  <div className=" mb-2"></div>
                  <div id="organisation-field">
                    <input
                      type="text"
                      id="organisationName"
                      className={`form-control ${(formData.formValidated && !formData.organisationNameValid)?"is-invalid":""} text-09`}
                      placeholder="Organisation"
                      name="organisationName"
                      onChange={handleChange}
                      value={formData.organisationName}
                    />
                    <div className="invalid-feedback">
                      {formData.organisationNameFeedback}
                    </div>
                    <div className=" mb-3"></div>
                  </div>

                    <div className="row">

                        {/* FNAME */}
                        <div className="col">
                            <input
                                type="text"
                                id="firstName"
                                className={`form-control ${(formData.formValidated && !formData.firstNameValid)?"is-invalid":""} text-09`}
                                name="firstName"
                                placeholder="First name"
                                onChange={handleChange}
                                value={formData.firstName}
                            />
                            <div className="invalid-feedback">
                                {formData.firstNameFeedback}
                            </div>
                        </div>

                        {/* LNAME */}
                        <div className="col">
                            <input
                                type="text"
                                id="last-name"
                                className={`form-control ${(formData.formValidated && !formData.lastNameValid)?"is-invalid":""} text-09`}
                                name="lastName"
                                placeholder="Last name"
                                onChange={handleChange}
                                value={formData.lastName}
                            />
                            <div className="invalid-feedback">
                                {formData.lastNameFeedback}
                            </div>
                        </div>
                        <div className=" mb-3"></div>
                    </div>



                    {/* PASSWORD */}
                    <div id="password-field">
                        <input
                            type="password"
                            placeholder="Enter password"
                            id="password"
                            className={`form-control ${(formData.formValidated && !formData.passwordValid)?"is-invalid":""} text-09`}
                            name="password"
                            onChange={handleChange}
                            value={formData.password}
                        />
                        <div className="invalid-feedback">
                            {formData.passwordFeedback}
                        </div>
                        <div className=" mb-3"></div>
                    </div>

                    {/*REPEAT PASSWORD*/}
                    <div id="confirm-password-field">
                        <input
                            type="password"
                            placeholder="Confirm password"
                            id="confirm-password"
                            className={`form-control ${(formData.formValidated && !formData.confirmedPasswordValid)?"is-invalid":""} text-09`}
                            name="confirmedPassword"
                            onChange={handleChange}
                            value={formData.confirmedPassword}
                        />
                        <div className="invalid-feedback">
                            {formData.confirmedPasswordFeedback}
                        </div>
                        <div className=" mb-3"></div>
                    </div>


                    {/*MAILING LIST CHECKBOX*/}
                    <label
                        id="agree-terms-field"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            userSelect: "none",
                            marginBottom: "1rem",
                            cursor: "pointer",
                            flexWrap: "wrap",
                        }}
                    >
                        <input
                            type="checkbox"
                            id="newsletterConsent"
                            className={`${(formData.formValidated && !formData.agreeTermsValid)?"is-invalid":""} text-09`}
                            name="newsletterConsent"
                            onChange={handleChange}
                            checked={formData.newsletterConsent}
                        />

                        <div
                            style={{
                                fontSize: strings.sizes.textSecondary,
                                color: strings.colors.textPrimary,
                                fontWeight: strings.weights.medium,
                            }}
                        >
                            &nbsp;&nbsp;I agree to join the Stratbox mailing list&nbsp;
                        </div>

                        <div className=" mb-3"></div>

                    </label>


                    {/*TERMS CHECKBOX*/}
                    <label
                        id="agree-terms-field"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            userSelect: "none",
                            marginBottom: "1rem",
                            cursor: "pointer",
                            flexWrap: "wrap",
                        }}
                    >
                        <input
                            type="checkbox"
                            id="agreeTerms"
                            className={`${(formData.formValidated && !formData.agreeTermsValid)?"is-invalid":""} text-09`}
                            name="agreeTerms"
                            onChange={handleChange}
                            checked={formData.agreeTerms}
                        />

                        <div
                            style={{
                                fontSize: strings.sizes.textSecondary,
                                color: strings.colors.textPrimary,
                                fontWeight: strings.weights.medium,
                            }}
                        >
                            &nbsp;&nbsp;By clicking the "Join" button you agree to&nbsp;
                        </div>
                        <a
                            href={"https://www.imagedreality.com/terms-and-conditions/"}
                            target={"none"}
                            style={{
                                fontSize: strings.sizes.textSecondary,
                                fontWeight: strings.weights.medium,
                            }}
                        >
                            Terms & Conditions
                        </a>

                        <div
                            style={{
                                fontSize: strings.sizes.textSecondary,
                                color: strings.colors.textPrimary,
                                fontWeight: strings.weights.medium,
                            }}
                        >
                            &nbsp;and&nbsp;
                        </div>

                        <a
                            href={"https://imagedreality.com/privacy-policy/"}
                            target={"none"}
                            style={{
                                fontSize: strings.sizes.textSecondary,
                                fontWeight: strings.weights.medium,
                            }}
                        >
                            Privacy Policy
                        </a>

                        <div className=" mb-3"></div>
                        <div className="invalid-feedback">
                            {formData.agreeTermsFeedback}
                        </div>
                    </label>

                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        color: strings.colors.actionNegative,
                        fontWeight: strings.weights.medium,
                        fontSize: strings.sizes.textPrimary,
                        margin: "1rem",
                    }}
                >
                    {formData.joinFailedFeedback}

                    {formData.joinFailedFeedback !== "" &&
                        <a
                            href={"https://www.imagedreality.com/contact/"}
                            target={"none"}
                            style={ {
                                fontSize: strings.sizes.textSecondary,
                            }}
                        >
                            Trouble signing up?
                        </a>
                    }
                </div>

                <div>

                    <button
                        type="submit"
                        id="login-submit"
                        className="btn btn-stbx-primary form-control"
                    >
                        {formData.isJoining
                            ? <div
                                className="spinner-border spinner-border-sm text-light"
                                role="status"
                            />
                            : <span>Join</span>
                        }
                    </button>
                </div>
            </form>

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1rem",
                }}
            >
                <div
                    style={{
                        color: strings.colors.textPrimary,
                        fontWeight: strings.weights.medium,
                        fontSize: strings.sizes.textPrimary,
                        margin: "0.25rem"
                    }}
                >
                    Already have an account?
                </div>
                <div
                    style={{
                        color: strings.colors.actionNeutral,
                        fontWeight: strings.weights.medium,
                        fontSize: strings.sizes.textPrimary,
                        margin: "0.25rem",
                        cursor: "pointer"
                    }}
                    onClick={() => navigate("/login")}
                >
                    Sign in
                </div>
            </div>
        </div>
      </div>
    );
}

export default Signup;