
import strings from "../strings.json";


// export function debounce(fn: (...args: any[]) => void, ms: number): (...args: any[]) => void {
//     let timer: NodeJS.Timer | undefined;
//     return (...args: any[]) => {
//         clearTimeout(timer);
//         timer = setTimeout(() => {
//             timer = undefined;
//             fn(...args);
//         }, ms);
//     };
// }

type DebounceFunction = (...args: any[]) => void;

export function debounce(fn: DebounceFunction, ms: number): DebounceFunction {
    let timer: NodeJS.Timeout | null = null;

    return (...args: any[]) => {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            fn(...args);
        }, ms);
    };
}


export function redirectToUri(searchParams: URLSearchParams, defaultUri: string){
    let redirect = defaultUri;
    try{
        let domain = new URL(searchParams.get('continue') || "");
        if(domain.hostname.endsWith(".imagedreality.com")) redirect = domain.href;
    }catch(e){}
    window.location.href = redirect;
}

export function validatePassword(password:string){
    return password.match(/^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}()?\-"!@#%&\/\\,><':;|_~`+=])(?=.{8,})/) !== null;
}

export function validateEmail(email:string){

    const existingDomains = [
        "shell.com",
        "hess.com",
        "ypf.com",
    ];
    let patternMatch =  email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
    let existingOrgCheck = !existingDomains.includes(email.split("@")[1]);

    if (!patternMatch) {
        return {
            success: false,
            message: "Please enter a valid email address"
        }
    }
    else if (!existingOrgCheck) {
        return {
            success: false,
            message: "Your email domain belongs to an existing organisation. Please use another email address, or contact your administrator to request an invitation."
        }
    }
    else return {success: true, message: ""}
}

export async function signup(email: string, organisation_name: string, first_name:string, last_name:string, password:string, newsletter_consent:boolean){

    const response = await fetch(strings.uri.api_url + strings.uri.signup_path + "/signup", {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                "email": email,
                "organisation_name": organisation_name,
                "first_name": first_name,
                "last_name": last_name,
                "password": password,
                "newsletter_consent": newsletter_consent,
            })
    });

    if(!response.ok){
        return {error:"An error has occured: "+response.status, success:false};
    }

    const result = await response.json();
    return {error:result.error, success:result.payload}
}

export async function verify(email:string, verification_code:string) {
    const response = await fetch(strings.uri.api_url + strings.uri.signup_path + "/verify", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({"email": email, "verification_code": verification_code})
    });

    if (!response.ok) {
        return "An error has occured: " + response.status;
    }
    const result = await response.json();
    return result.error


}

export async function agreeTerms(email:string) {
    const response = await fetch(strings.uri.api_url + strings.uri.user_path + "/agree-terms", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({"email": email}),
        credentials: 'include'
    });

    if (!response.ok) {
        return "An error has occured: " + response.status;
    }
    const result = await response.json();
    return result
}
