// © Imaged Reality Ltd 2022. All Rights Reserved

import strings from '../strings.json';
import React, { useEffect, useRef, useState } from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";


const colors = [
    "#9e7bcc",
    "#f9c711",
    "#d1c0e8"
]

const logos = {
  "Stratbox Analytics": "analytics_VERT_MED.png",
  "Stratbox Connect": "connect_VERT_MED.png",
  "Stratbox Core Explorer" : "core-explorer_VERT_MED.png",
  "Stratbox Connect VR": "connect-vr_VERT_MED.png",
}


//                          WIN  -  MAC  -  WEB  -  VR
const osLogos = {
  'Stratbox Connect':       [true, true, false, false],
  'Stratbox Connect VR':    [true, false, false, true],
  'Stratbox Analytics':     [true, false, false, false],
  'Stratbox Core Explorer': [false, false, true, false]
};

const key_features = {
  "Stratbox Analytics": [
    "Quantitative Outcrop Interpretation",
    "Analytical tools",
    // "Global floating licences",
    // "Import Georeferenced & local models",
    // "Interpretation tools",
    // "Satellite imagery referencing",
    // "Layering system",
    // "Multi-model scene",
    // "Python API",
  ],
  "Stratbox Connect": [
    "The Digital twin for Outcrop Geology",
    "Collaboration & Training",
    // "Global floating licences",
    // "Interpretation tools",
    // "Data integration in 3D space",
    // "Access to satellite imagery",
    // "Remote collaboration",
    // "Scene creation",
    // "Access to cloud-based photogrammetry models anytime, anywhere (Enterprise only)",
  ],
  "Stratbox Core Explorer" : [
    "The Digital twin of Core Stores",
    "Breaking data silos with well core data",
    // "Global floating licences",
    // "Customisable log panel",
    // "Core table",
    // "Multi-well comparison",
    // "Automated data integration",
    // "Python API for Machine Learning",
  ],
  "Stratbox Connect VR": [
    "The Metaverse for Geoscience",
    "Immersion",
    // "Global floating licences",
    // "Virtual reality",
    // "Data integration in 3D space",
    // "Access to satellite imagery",
    // "Interpretation tools",
    // "Remote collaboration",
    // "VR scene creation (multimedia support)",
  ],
}


const ProductCard = (props) => {

  // console.log(props);
  // console.log(props.offer)

  const [interval, setInterval] = useState(props.interval);
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState(localStorage.getItem("cart"));


  const [existingInterval, setExistingInterval] = useState(undefined);

  const navigate = useNavigate();



  // Stripe will not allow multiple products with different billing intervals
  // (eg 1x Monthly + 2x Yearly subscriptions) - so we need to limit the selection
  // to the interval type to whichever is put into the cart:
  function checkCartForInterval() {
    let thisCart = localStorage.getItem("cart");
    console.log(thisCart);

    if (thisCart == null) {
        return;
    }
    if (Object.entries(JSON.parse(thisCart)).length > 0 ) {
        setExistingInterval(Object.entries(JSON.parse(thisCart))[0][1].interval);
        setInterval(existingInterval);
    }

    if (Object.entries(JSON.parse(thisCart)).length === 0 ) {
        setExistingInterval(undefined);
    }
  }

  // useEffect( () => {
  //
  //     checkCartForInterval();
  //
  //     const onStorage = () => {
  //         checkCartForInterval();
  //     }
  //
  //     window.addEventListener('storage', onStorage);
  //
  //     console.log("added");
  //
  //     return () => {
  //         window.removeEventListener('storage', onStorage);
  //         console.log("removed");
  //     };
  //
  // }, [])

  // useEffect(() => {
  //     checkCartForInterval();
  // }, [interval])

  useEffect(() => {
    setQuantity(1);
  }, [props.isBiz])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "15.625rem",
        height: "25rem",
        backgroundColor: strings.colors.backgroundPrimary,
        // border: `solid 0.125rem #EBEBEB`,
        border: `solid 0.125rem ${strings.colors.backgroundTertiary}`,
        borderRadius: "0.35rem",
        margin: "0.5rem",
        padding: "0rem 1.5rem",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          marginTop: "0.875rem",
          width: "100%",
          height: "6.875rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // border: "solid 1px black",
        }}
      >
        <img
          // src={props.offer.images[0]}
          src={logos[props.offer.name]}
          style={{
              width: "auto",
              height: "6.875rem",
          }}
          alt={`${props.offer.name}`}
        />
      </div>


      <div
        style={{
          // padding: "0rem",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          // paddingLeft: "1rem",
          // margin: "0rem",
          // marginLeft: "0rem",
          // marginRight: "0rem",
          // marginBottom: "0rem",
          // marginTop: "0.5rem",
          minHeight: "7rem",
          maxHeight: "7rem",
          // overflow: "hidden",
          // border: "solid 1px black",
        }}
      >

        {key_features[props.offer.name].map(feature => (

            <div
              style={{

                color: strings.colors.textPrimary,
                fontSize: "0.975rem",
                fontWeight: strings.weights.medium,
                width: "100%",
                padding: "0rem 1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
                // fontSize: "1.125rem",
                textAlign: "center",
                lineHeight: "0.975rem",
                // fontWeight: strings.weights.regular,
                // color: strings.colors.textPrimary,
                // listStylePosition: "outside",
              }}
            >
              {feature}
            </div>

          )
        )}
      </div>

      <div
        style={{
          fontSize: strings.sizes.iconPrimary,
          lineHeight: strings.sizes.iconPrimary,
          fontWeight: strings.weights.light,
          color: strings.colors.textPrimary,
          userSelect: "none",
        }}
      >
        {props.offer.metadata.type.toString()[0].toUpperCase() + props.offer.metadata.type.toString().substring(1) } licenses
      </div>


      <div
        style={{
          fontSize: strings.sizes.iconPrimary,
          lineHeight: strings.sizes.iconPrimary,
          fontWeight: strings.weights.light,
          color: strings.colors.textSecondary,
          userSelect: "none",
          // border: "solid 1px black",
          // marginTop: "0",
        }}
      >
        starting
        ${(props.offer.prices[props.offer.prices.findIndex(price => price.recurring.interval === interval)]?.unit_amount / 100 * quantity).toLocaleString()}
        / mo
      </div>



      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >

        {/*WINDOWS*/}
        {osLogos[props.offer.name][0] &&
          <div
            className={'font-awesome-brands'}
            style={{
              margin: "0rem 0.5rem",
              color: strings.colors.textPrimary,
              fontSize: "1.5rem",
              lineHeight: "1.5rem",
              textDecoration: "none",
              WebkitTouchCallout: "none",
              WebkitUserSelect: "none",
              htmlUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
            }}
          ></div>
        }

        {/*APPLE*/}
        {osLogos[props.offer.name][1] &&
          <div
            className={'font-awesome-brands'}
            style={{
              margin: "0rem 0.313rem",
              color: strings.colors.textPrimary,
              fontSize: "1.5rem",
              lineHeight: "1.5rem",
              textDecoration: "none",
              WebkitTouchCallout: "none",
              WebkitUserSelect: "none",
              htmlUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
            }}
          ></div>
        }


        {osLogos[props.offer.name][2] &&
          <div
            className={'font-awesome-free-solid'}
            style={{
              margin: "0rem 0.313rem",
              color: strings.colors.textPrimary,
              fontSize: "1.5rem",
              lineHeight: "1.5rem",
              textDecoration: "none",
              WebkitTouchCallout: "none",
              WebkitUserSelect: "none",
              htmlUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
            }}
          >
          </div>
        }

        {osLogos[props.offer.name][3] &&
          <div
            className={'font-awesome-free-solid'}
            style={{
              margin: "0rem 0.313rem",
              color: strings.colors.textPrimary,
              fontSize: "1.5rem",
              lineHeight: "1.5rem",
              textDecoration: "none",
              WebkitTouchCallout: "none",
              WebkitUserSelect: "none",
              htmlUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
            }}
          >
          </div>
        }


      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "1.25rem",
        }}
      >

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.125rem",
            width: "8rem",
            height: "2.063rem",
            fontWeight: strings.weights.regular,
            color: strings.colors.backgroundPrimary,
            backgroundColor: strings.colors.actionNeutral,
            borderRadius: "0.8125rem",
            cursor: "pointer",
            userSelect: "none",
            justifySelf: "flex-end",
            alignSelf: "flex-end",
            // opacity: props.offer.metadata.type === "academia"?
            // props.userDetails === undefined
            // || props.userDetails === null
            // || props.isEdu? "1.0" : "0.25" : "1"
          }}

          onClick={ async () => {
            navigate(`/${props.offer.name}`, {replace: true} )
          }}
        >
          Select
        </div>

  </div>

    </div>
  )
}

export default ProductCard;