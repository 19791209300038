import './style.css';

import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const cart = localStorage.getItem("cart");

root.render(
  // <React.StrictMode>
            <App/>
  // </React.StrictMode>
);

