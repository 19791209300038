// © Imaged Reality Ltd 2022. All Rights Reserved

import strings from '../strings.json';
import React, { useEffect, useRef, useState } from "react";
import {useNavigate} from "react-router-dom";

const colors = [
    "#9e7bcc",
    "#f9c711",
    "#d1c0e8"
]

const ideals = {
  "Suite": "Great for cross-disciplinary workflows",
  "Geo": "Ideal for geologists, mining engineers and drone surveyors",
}

const BundleCard = (props) => {

    console.log(props.offer)

    const [interval, setInterval] = useState('month');
    const [quantity, setQuantity] = useState(1);
    const [cart, setCart] = useState(localStorage.getItem("cart"));

    // const existingInterval = useRef();
    const [existingInterval, setExistingInterval] = useState(undefined);

    const navigate = useNavigate();

  // Stripe will not allow multiple products with different billing intervals
    // (eg 1x Monthly + 2x Yearly subscriptions) - so we need to limit the selection
    // to the interval type to whichever is put into the cart:
    function checkCartForInterval() {
        let thisCart = localStorage.getItem("cart");
        console.log(thisCart);

        if (thisCart == null) {
            return;
        }
        // if (Object.entries(JSON.parse(thisCart)).length > 0 ) {
        //     existingInterval.current = Object.entries(JSON.parse(thisCart))[0][1].interval;
        //     setInterval(existingInterval.current);
        // }
        //
        // if (Object.entries(JSON.parse(thisCart)).length === 0 ) {
        //     existingInterval.current = undefined;
        // }

        if (Object.entries(JSON.parse(thisCart)).length > 0 ) {
            setExistingInterval(Object.entries(JSON.parse(thisCart))[0][1].interval);
            setInterval(existingInterval);
        }

        if (Object.entries(JSON.parse(thisCart)).length === 0 ) {
            setExistingInterval(undefined);
        }

    }

    useEffect( () => {

        checkCartForInterval();

        const onStorage = () => {
            checkCartForInterval();
        }

        window.addEventListener('storage', onStorage);

        console.log("added");

        return () => {
            window.removeEventListener('storage', onStorage);
            console.log("removed");
        };

    }, []);


    useEffect(() => {
        checkCartForInterval();
    }, [interval]);

    useEffect(() => {
      setQuantity(1);
    }, [props.isBiz])


  if (!strings) {
    return <div
      className={"spinner"}
    />;
  }

  else return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          width: "32rem",
          minWidth: "32.5rem",
          height: "15rem",
          backgroundColor: strings.colors.backgroundPrimary,
          margin: "0.5rem",
          flexWrap: "wrap",
          border: `solid 0.125rem ${strings.colors.backgroundTertiary}`,
          borderRadius: "0.35rem",
          // margin: "0.5rem",
          padding: "0rem 0.5rem",
          justifyContent: "flex-start",
          // transform: window.screen.width < 560? "translateX(-20)" : "",
        }}
      >



          {/*BASE*/}
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              padding: "0.6125rem",
              // border: "solid 1px grey",
            }}
          >


            {/*LEFT*/}
            <div
                style={{
                  width: "50%",
                  height: "100%",
                  paddingLeft: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "space-around",
                  // border: "solid 1px grey",
                }}
            >

              {/*NAME*/}
              <div
                style={{
                  fontSize: strings.sizes.iconSecondary,
                  fontWeight: strings.weights.medium,
                  color: strings.colors.textTertiary,
                  userSelect: "none",
                }}
              >

                <div
                  style={{
                    fontSize: "1.375rem",
                    fontWeight: strings.weights.medium,
                    color: strings.colors.textPrimary,
                    userSelect: "none",
                  }}
                >
                  {props.offer.name.split(":")[0]}
                </div>

              </div>

              {/*LOGOS*/}
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "flex-start"
                }}
              >

                <img
                  src={"./connect_SQ_MED.png"}
                  style={{
                    height: "3.125rem",
                    width: "3.125rem",
                  }}
                />

                <img
                  src={"./connect-vr_SQ_MED.png"}
                  style={{
                    marginLeft: "0.6125rem",
                    height: "3.125rem",
                    width: "3.125rem",
                  }}
                />

                <img
                  src={"./analytics_SQ_MED.png"}
                  style={{
                    marginLeft: "0.6125rem",
                    height: "3.125rem",
                    width: "3.125rem",
                  }}
                />

                {props.offer.name.includes("Suite") &&
                  <img
                    src={"./core-explorer_SQ_MED.png"}
                    style={{
                      marginLeft: "0.6125rem",
                      height: "3.125rem",
                      width: "3.125rem",
                    }}
                  />
                }

              </div>


              {/*PRODUCTS*/}
              <div
                style={{
                  marginTop: "1rem",
                  fontSize: "1.125rem",
                  fontWeight: strings.weights.regular,
                  color: strings.colors.textPrimary,
                  userSelect: "none",
                  textAlign: "left",
                }}
              >
                {props.offer.name.split(":")[1]}
              </div>


              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1.125rem",
                  marginTop: "0.5rem",
                  // lineHeight: "1.125rem",
                  width: "8rem",
                  height: "2.063rem",
                  fontWeight: strings.weights.regular,
                  color: strings.colors.backgroundPrimary,
                  backgroundColor: strings.colors.actionNeutral,
                  borderRadius: "0.8125rem",
                  cursor: "pointer",
                  // padding: "0.35rem 2rem",
                  userSelect: "none",
                  justifySelf: "center",
                  alignSelf: "center",
                  // opacity: props.offer.metadata.type === "academia"?
                  //   props.userDetails === undefined
                  //   || props.userDetails === null
                  //   || props.isEdu? "1.0" : "0.25" : "1"
              
                }}

                onClick={ async () => {
                  navigate(`/${props.offer.name.split(":")[0]}`, {replace: true} )
                }}
              >
                Select
              </div>

            </div>

            {/*RIGHT*/}
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // border: "solid 1px grey",
              }}
            >

              <div
                style={{
                  fontSize: "1.375rem",
                  lineHeight: "1.375rem",
                  fontWeight: strings.weights.light,
                  color: strings.colors.textPrimary,
                  marginTop: "0rem",
                  userSelect: "none",
                }}
              >
                {props.offer.metadata.type.toString()[0].toUpperCase() + props.offer.metadata.type.toString().substring(1) } licenses
              </div>

              <div
                style={{
                  fontSize: "1.375rem",
                  lineHeight: "1.375rem",
                  fontWeight: strings.weights.light,
                  color: strings.colors.textPrimary,
                  marginTop: "0.25rem",
                  userSelect: "none",
                }}
              >
                starting ${(props.offer.prices[props.offer.prices.findIndex(price => price.recurring.interval === "month")]?.unit_amount / 100 * quantity).toLocaleString()} / mo
              </div>

              <div
                style={{
                  marginTop: "1rem",
                  width: "6.25rem",
                  height: "1.875rem",
                  backgroundColor: strings.colors.discount,
                  borderRadius: "0.25rem",
                  color: strings.colors.textPrimary,
                  fontSize: "1rem",
                  fontWeight: strings.weights.bold,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Save {props.offer.name === "Stratbox Premium: Connect + VR + Analytics" ? "12" : "15"}%
              </div>

              <div
                style={{
                  marginTop: "0.875rem",
                  color: strings.colors.textPrimary,
                  fontSize: "0.875rem",
                  fontWeight: strings.weights.medium,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {props.offer.name.includes("Suite")?
                  ideals["Suite"]
                  :
                  ideals["Geo"]
                }
              </div>

            </div>

          </div>

        </div>
    )
}

export default BundleCard;