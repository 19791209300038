import strings from "../strings.json";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getOfferings} from "../shared/merchant";
import { addToCart } from "../shared/cart";

const showBorders = false;
interface Product {
  id: string,
  object: string,
  active: boolean,
  attributes: any[],
  created: number,
  default_price: string,
  description: string,
  features: any[],
  images: string[],
  livemode: boolean,
  metadata: Metadata,
  name: string,
  package_dimensions: any,
  shippable: any,
  statement_descriptor: any,
  tax_code: string,
  type: string,
  unit_label: any,
  updated: number,
  url: any
  prices: Price[];
}

interface Metadata {
  type: string
}

interface Price {
  id: string,
  object: string,
  active: boolean,
  billing_scheme: string,
  created: number,
  currency: string,
  custom_unit_amount: any,
  livemode: boolean,
  lookup_key: any,
  metadata: object,
  nickname: any,
  product: any,
  recurring: {
    aggregate_usage: any,
    interval: any,
    interval_count: number,
    trial_period_days: any,
    usage_type: string
  },
  tax_behavior: string,
  tiers_mode: any,
  transform_quantity: any,
  type: string,
  unit_amount: number,
  unit_amount_decimal: string
}

interface ProductDatum {
  [key: string]:
  {
    headline: string,
    features: string[],
    table: string[],
    data: {
      enterprise: (boolean | string)[],
      business: (boolean | string)[],
      academia: (boolean | string)[]
    }
  };
}

const productData: ProductDatum = {
  "Stratbox Core Explorer": {
    headline: "The Digital twin of well core data",
    features: [
      "Global floating licences",
      "Customisable log panel",
      "Core table",
      "Multi-well comparison",
      "Automated data integration",
      "Annotations & interpretation tools",
      "On-demand access",
      "Remote collaboration in real time",
      "Python API for machine learning",
      "Friendly user interface",
    ],
    table: [
      "Scheduled enhancements",
      "Online Knowledge Base",
      "Expedited Support",
      "Onboarding planning & support",
      "Key Account Manager",
      "Ability to influence road map",
      "Encryption at rest and in transit"
    ],
    data: {
      enterprise: [
        true, true, true, true, true, true, true
      ],
      business: [
        true, true, false, false, false, false, true
      ],
      academia: [
        true, true, false, false, false, false, true,
      ]
    }
  },

  "Stratbox Analytics": {
    headline: "Quantitative outcrop interpretation",
    features: [
      "Global floating licences",
      "Import georeferenced & local models",
      "Interpretation tools",
      "Satellite imagery referencing",
      "Layering system",
      "Multi-model scene",
      "Ground control point import",
      "Shape file export",
      "Python API",
      "Friendly user interface, based on gaming technology",
    ],
    table: [
      "Scheduled enhancements",
      "Online Knowledge Base",
      "Expedited Support",
      "Onboarding planning & support",
      "Key Account Manager",
      "Ability to influence road map",
      "Encryption at rest and in transit"
    ],
    data: {
      enterprise: [
        true, true, true, true, true, true, true
      ],
      business: [
        true, true, false, false, false, false, true
      ],
      academia: [
        true, true, false, false, false, false, true,
      ]
    }
  },

  "Stratbox Connect": {
    headline: "The Digital twin for outcrop geology",
    features: [
      "Global floating licences",
      "Interpretation tools",
      "Data integration in 3D space",
      "Access to satellite imagery",
      "Remote collaboration",
      "Scene creation",
      "Access to cloud-based photogrammetry models anytime, anywhere (Enterprise only)",
      "Free access to RPS global outcrop analogues (Academia only)",
      "Friendly user interface, based on gaming technology",
    ],
    table: [
      "Scheduled enhancements",
      "Online Knowledge Base",
      "Expedited Support",
      "Onboarding planning & support",
      "Key Account Manager",
      "Ability to influence road map",
      "Encryption at rest and in transit"
    ],
    data: {
      enterprise: [
        true, true, true, true, true, true, true
      ],
      business: [
        true, true, false, false, false, false, true
      ],
      academia: [
        true, true, false, false, false, false, true,
      ]
    }
  },

  "Stratbox Connect VR": {
    headline: "The metaverse for Geoscience",
    features: [
      "Global floating licences",
      "Virtual reality",
      "Data integration in 3D space",
      "Access to satellite imagery",
      "Interpretation tools",
      "Remote collaboration",
      "VR scene creation (multimedia support)",
      "Immersion",
      "Spectator camera",
      "Friendly user interface, based on gaming technology",
      "Runs on Quest, Focus3 and Vive"
    ],
    table: [
      "Scheduled enhancements",
      "Online Knowledge Base",
      "Expedited Support",
      "Onboarding planning & support",
      "Key Account Manager",
      "Ability to influence road map",
      "Encryption at rest and in transit"
    ],
    data: {
      enterprise: [
        true, true, true, true, true, true, true
      ],
      business: [
        true, true, false, false, false, false, true
      ],
      academia: [
        true, true, false, false, false, false, true,
      ]
    }
  },

  "Stratbox Premium: Connect + VR + Analytics": {
    headline: "Ideal for geologists, mining engineers and drone surveryors",
    features: [
      "Connect: The Digital twin for outcrop geology",
      "Connect VR: The metaverse for Geoscience",
      "Analytics: Quantitative outcrop interpretation",
    ],
    table: [
      "Scheduled enhancements",
      "Online Knowledge Base",
      "Expedited Support",
      "Onboarding planning & support",
      "Key Account Manager",
      "Ability to influence road map",
      "Encryption at rest and in transit"
    ],
    data: {
      enterprise: [
        true, true, true, true, true, true, true
      ],
      business: [
        true, true, false, false, false, false, true
      ],
      academia: [
        true, true, false, false, false, false, true,
      ]
    }
  },

  "Stratbox Suite: Connect + VR + Analytics + Core Explorer": {
    headline: "Great for cross-disciplinary workflows",
    features: [
      "Connect: The Digital twin for outcrop geology",
      "Connect VR: The metaverse for Geoscience",
      "Analytics: Quantitative outcrop interpretation",
      "Core Explorer: The Digital twin of well core data",
    ],
    table: [
      "Scheduled enhancements",
      "Online Knowledge Base",
      "Expedited Support",
      "Onboarding planning & support",
      "Key Account Manager",
      "Ability to influence road map",
      "Encryption at rest and in transit"
    ],
    data: {
      enterprise: [
        true, true, true, true, true, true, true
      ],
      business: [
        true, true, false, false, false, false, true
      ],
      academia: [
        true, true, false, false, false, false, true,
      ]
    }
  }
}


type CustomerType = "enterprise" | "business" | "academia";

interface LicenseDatum {
  [key: string]: {
    [key in CustomerType]: {
      headline: string;
      caveat: string | boolean;
    };
  };
}

const licenseData: { [key in CustomerType]: { headline: string; caveat: string | boolean } } = {
  enterprise: {
    headline: "For organisations with global scale, security and support needs.",
    caveat: false,
  },
  business: {
    headline: "For individuals and small organisations*, commercial use.",
    caveat: "* annual revenue up to US $500,000."
  },
  academia: {
    headline: "Students & Professors currently enrolled in academic institutions, non-commercial use.",
    caveat: "For University classroom licences with greater support needs, get in touch."
  }
}

interface PreviewImage {
  [key: string]: string;
}

const previewImages: PreviewImage = {
  "Stratbox Core Explorer": "ce_preview_LO.png",
  "Stratbox Analytics": "ana_preview_LO.png",
  "Stratbox Connect": "con_preview_LO.png",
  "Stratbox Connect VR": "cvr_preview_LO.png",
  "Stratbox Suite: Connect + VR + Analytics + Core Explorer": "ce_preview_HI.png",
  "Stratbox Premium: Connect + VR + Analytics": "ce_preview_HI.png",
}

interface TitleImage {
  [key: string]: string;
}

const titleImages: TitleImage = {
  "Stratbox Core Explorer": "./core-explorer_HOR_MED.png",
  "Stratbox Connect": "./connect_HOR_MED.png",
  "Stratbox Connect VR": "./connect-vr_HOR_MED.png",
  "Stratbox Analytics": "./analytics_HOR_MED.png",
  "Stratbox Suite: Connect + VR + Analytics + Core Explorer": "./analytics_HOR_MED.png",
  "Stratbox Premium: Connect + VR + Analytics": "./analytics_HOR_MED.png",
}


const ProductPage = (props: any) => {

  const [offers, setOfferings] = useState<Product[]>(props.offers);
  const [selectedCustomerType, setCustomerType] = useState<CustomerType>('business');
  const [interval, setInterval] = useState('year');
  const [existingInterval, setExistingInterval] = useState(undefined);
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState(localStorage.getItem("cart"));

  const navigate = useNavigate();

  const [matchingOffer, setMatchingOffer] = useState<Product | undefined>(undefined);
  const [matchingPrice, setMatchingPrice] = useState<Price | undefined>(undefined);

  function checkCartForInterval() {
    let thisCart = localStorage.getItem("cart");
    console.log(thisCart);

    if (thisCart == null) {
      return;
    }
    if (Object.entries(JSON.parse(thisCart)).length > 0 ) {

      console.log("FOUND EXISTING PRODUCTS IN CART")
      // @ts-ignore
      setExistingInterval(Object.entries(JSON.parse(thisCart))[0][1].interval);
      // @ts-ignore
      setInterval(existingInterval);
    }

    if (Object.entries(JSON.parse(thisCart)).length === 0 ) {
      // existingInterval.current = undefined;
      setExistingInterval(undefined);
    }
  }



  useEffect(() => {

    console.log("UE - []");
    document.title = `${props.productName} | Stratbox`;

    const queryString = window.location.search;
    console.log(queryString);

    const urlParams = new URLSearchParams(queryString);
    console.log(urlParams);

    const customer_type = urlParams.get('customer_type');
    console.log(customer_type);

    if (customer_type) {

      console.log("Setting customer type from url params")
      setCustomerType(customer_type as CustomerType);
    };


    checkCartForInterval();

    const onStorage = () => {
      checkCartForInterval();
    }

    window.addEventListener('storage', onStorage);

    console.log("added");

    console.log(offers);

    if (offers.length === 0) {

      console.log("No offers")

      if (props.offers.length === 0) {

        console.log("No offers in root props, fetching...")
        try {
          getOfferings().then((res) => (res.json())
            .then((data) => {
              console.log(data);
              setOfferings(data.offerings);
              // props.setOfferings(data.offerings)

              let foundOffer = data.offerings.find((offer: Product) => offer.name === props.productName && offer.metadata.type === selectedCustomerType);
              console.log(foundOffer);
              if (foundOffer) {
                setMatchingOffer(foundOffer);

                let foundPrice = foundOffer.prices.find((price: Price) => price.recurring.interval === interval);
                if (foundPrice) {
                  setMatchingPrice(foundPrice);
                }
                else {
                  console.log("no matching price")
                }
              }

              else {
                console.log("no matching offer")
              }
            })
          );

          console.log(offers);
        }
        catch (e) {
          console.log("ERROR Fetching offerings");
          console.log(e);

          setOfferings([]);
        }

      }

      else {
        console.log("Got offers from root props");

        let foundOffer = props.offers.find((offer: Product) => offer.name === props.productName && offer.metadata.type === selectedCustomerType);
        console.log(foundOffer);
        if (foundOffer) {
          setMatchingOffer(foundOffer);

          let foundPrice = foundOffer.prices.find((price: Price) => price.recurring.interval === interval);
          if (foundPrice) {
            setMatchingPrice(foundPrice);
          }
          else {
            console.log("no matching price")
          }
        }

        else {
          console.log("no matching offer")
        }
        setOfferings(props.offers);
      }
    }


    return () => {
      window.removeEventListener('storage', onStorage);
      console.log("removed");
    };

  }, []);



  useEffect(() => {

    console.log("UE - interval");

    checkCartForInterval();

    if (matchingOffer !== undefined) {
      let foundPrice = matchingOffer.prices.find((price: Price) => price.recurring.interval === interval)
      console.log(foundPrice);
      if (foundPrice !== undefined) {
        setMatchingPrice(foundPrice);
      }
      else {
        console.log("* UNABLE TO FIND MATCHING PRICE *");
      }
    }
    else {
      console.log("* NO MATCHING OFFER *");
    }

  }, [interval])



  useEffect(() => {

    console.log("UE - selectedCustomerType");

    if (offers.length > 0) {

      let foundOffer = offers.find((offer: Product) => offer.name === props.productName && offer.metadata.type === selectedCustomerType.toString());
      console.log(foundOffer);
      if (foundOffer) {
        setMatchingOffer(foundOffer);

        let foundPrice = foundOffer.prices.find((price: Price) => price.recurring.interval === interval);

        if (foundPrice) {
          setMatchingPrice(foundPrice);
        }
        else {
          console.log("* UNABLE TO FIND MATCHING PRICE *");
        }
      }
      else {
        console.log("* UNABLE TO FIND MATCHING OFFER *");
      }
    }
    else {
      console.log("* NO OFFERS *");
    }

  }, [selectedCustomerType])



  useEffect(() => {
    console.log("UE - props.isBiz");

    setQuantity(1);
  }, [props.isBiz])



  const ProductTable: React.FC<{ product: keyof ProductDatum }> = ({product}) => {
    const productInfo = productData[product];

    return (

      <table
        style={{
          borderRadius: "0.5rem",
          backgroundColor: strings.colors.backgroundPrimary,
          maxWidth: "50rem",
          maxHeight: "12.25rem",
          height: "12.25rem",
          minWidth: "45rem",
          // padding: "2rem",
          // overflow: "hidden",
          // outline: "solid 1px black"
          // padding: "2rem",
        }}
      >
        <thead>
          <tr>
            <th></th>
            {productInfo.table.map((header, index) => (
              <th
                key={index}
                style={{
                  fontSize: strings.sizes.textPrimary,
                  fontWeight: strings.weights.regular,
                  color: strings.colors.textPrimary,
                  textAlign: "center",
                  verticalAlign: "top",
                  WebkitTouchCallout: "none",
                  WebkitUserSelect: "none",
                  MozUserSelect: "none",
                  msUserSelect: "none",
                  userSelect: "none",
                  // display: "flex",
                  // justifyContent: "flex-start",
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody
          style={{
            // border: "solid 1px black",
            // borderRadius: "0.5rem",
            // padding: "1rem",
            // maxHeight: "12.5rem",
            // height: "12.5rem",

            maxHeight: "7.75rem",
            height: "7.75rem",
          }}
        >
          {['enterprise', 'business', 'academia'].map((type, rowIndex) => (
          <tr
            className={`support-table-row ${type === selectedCustomerType? "active" : ""}`}
            key={type}
            style={{
              // backgroundColor: rowIndex === 1? "" : "#F4F4F4",
              borderRadius: "0.5rem",
              // fontSize: strings.sizes.textPrimary,
              // fontWeight: strings.weights.medium,
              // color: strings.colors.textPrimary,
              // textAlign: "left",
              cursor: "pointer",
            }}
            onClick={() => {
              setCustomerType(type as CustomerType);
            }}
          >
            <td
              style={{
                borderRadius: rowIndex === 2? "0 0  0 0.5rem" : "",
                fontSize: "1rem",
                paddingLeft: "1rem",
                fontWeight: strings.weights.medium,
                color: strings.colors.textPrimary,
                WebkitTouchCallout: "none",
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                msUserSelect: "none",
                userSelect: "none",
                // textAlign: "left",
              }}

            >
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </td>

            {productInfo.data[type as CustomerType].map((item, cellIndex) => (

              typeof item === 'boolean' ?

                <td

                  key={cellIndex}
                  style={{
                    fontSize: strings.sizes.textPrimary,
                    fontWeight: strings.weights.medium,
                    color: strings.colors.textPrimary,
                    textAlign: "center",
                    borderRadius: rowIndex === 2 && cellIndex === productInfo.data[type as CustomerType].length - 1? "0 0 0.5rem 0" : "",

                  }}
                >
                  {item ?

                    <div
                      className={'font-awesome-free-solid'}
                      key={cellIndex}
                      style={{
                        fontSize: "1rem",
                        color: strings.colors.textPrimary,
                        textAlign: "center",
                        textDecoration: "none",
                        WebkitTouchCallout: "none",
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                        userSelect: "none",
                      }}
                    ></div>

                    :

                    <div
                      className={'font-awesome-free-regular'}
                      key={cellIndex}
                      style={{
                        fontSize: "1rem",
                        color: strings.colors.textPrimary,
                        textAlign: "center",
                        opacity: "0.15",
                        textDecoration: "none",
                        WebkitTouchCallout: "none",
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                        userSelect: "none",
                      }}
                    ></div>
                  }
                </td>

                :

                <td
                  key={cellIndex}
                  style={{
                    fontSize: "1rem",
                    fontWeight: strings.weights.regular,
                    color: strings.colors.textPrimary,
                    textAlign: "center",
                  }}
                >
                  {item}
                </td>
            ))}
          </tr>
        ))}
        </tbody>

      </table>
    );
  };


return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: strings.colors.backgroundSecondary,
        flexGrow: "2",
        width: props.cartOpen? `calc(${window.innerWidth}px - 22rem)` : "100%",
        opacity: props.cartOpen? 0.35 : 1,
        cursor: props.cartOpen? "pointer" : "default",
        paddingBottom: "1rem",

      }}
      onClick={() => {
        console.log("setexpanded");
        props.cartOpen && props.setCartOpen(false)
      }}
    >

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "1200px",
          backgroundColor: strings.colors.backgroundSecondary,
          padding: "0rem",
          border: showBorders? "solid 1px black" : "none"
        }}
      >

        {/*NAV ROW*/}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "1.5rem",
            width: "100%",
          }}
        >

          {/*BACK TO PRODUCTS*/}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => navigate(`/plans`, {replace: true} )}
          >
            <div
              className={'material-icons'}
              style={{
                opacity: "1",
                fontSize: "1.375rem",
                lineHeight: "1.375rem",
                textDecoration: "none",
                WebkitTouchCallout: "none",
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                msUserSelect: "none",
                userSelect: "none",
                color: strings.colors.action,
              }}
            >
              arrow_back
            </div>

            <div
              style={{
                fontSize: strings.sizes.iconSecondary,
                lineHeight: strings.sizes.iconSecondary,
                color: strings.colors.textPrimary,
                fontWeight: strings.weights.regular,
              }}
            >
              &nbsp;back to products
            </div>
          </div>
        </div>


        {/*TITLE ROW*/}
        <div
          style={{
            marginTop: "0.8rem",
            display: "flex",
            width: "100%",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexWrap: "nowrap",
            height: "6.25rem",
          }}
        >

          {props.productName.split(":")[0] === "Stratbox Premium"?

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: "22.25rem"
              }}
            >

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  height: "2.25rem",
                }}
              >
                <div
                  style={{
                    fontSize: "1.85rem",
                    lineHeight: "1.85rem",
                    color: strings.colors.textTertiary,
                    fontWeight: strings.weights.regular
                  }}
                >
                  Stratbox
                </div>
                <div
                  style={{
                    fontSize: "1.85rem",
                    lineHeight: "1.85rem",
                    color: strings.colors.textPrimary,
                    fontWeight: strings.weights.medium
                  }}
                >
                  &nbsp;&nbsp;Geo
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  minWidth: "22.25rem",
                  justifyContent: "center"
                }}
              >

                <img
                  src={"connect_SQ_MED.png"}
                  style={{
                    height: "4rem",
                    marginLeft: "0.25rem"
                  }}
                />
                <img
                  src={"connect-vr_SQ_MED.png"}
                  style={{
                    height: "4rem",
                    marginLeft: "0.25rem"
                  }}
                />
                <img
                  src={"analytics_SQ_MED.png"}
                  style={{
                    height: "4rem",
                    marginLeft: "0.25rem"
                  }}
                />
              </div>
            </div>

            : props.productName.split(":")[0] === "Stratbox Suite"?
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "22.25rem"
                  // border: "solid 1px blue",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    height: "2.25rem",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1.85rem",
                      lineHeight: "1.85rem",
                      color: strings.colors.textTertiary,
                      fontWeight: strings.weights.regular
                    }}
                  >
                    Stratbox
                  </div>
                  <div
                    style={{
                      fontSize: "1.85rem",
                      lineHeight: "1.85rem",
                      color: strings.colors.textPrimary,
                      fontWeight: strings.weights.medium
                    }}
                  >
                    &nbsp;&nbsp;Suite
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    height: "4rem",
                    justifyContent: "center",
                    // marginLeft: "0.25rem"
                  }}
                >
                  <img
                    src={"connect_SQ_MED.png"}
                    style={{
                      height: "4rem",
                      marginLeft: "0.25rem"
                    }}
                  />
                  <img
                    src={"connect-vr_SQ_MED.png"}
                    style={{
                      height: "4rem",
                      marginLeft: "0.25rem"
                    }}
                  />
                  <img
                    src={"analytics_SQ_MED.png"}
                    style={{
                      height: "4rem",
                      marginLeft: "0.25rem"
                    }}
                  />
                  <img
                    src={"core-explorer_SQ_MED.png"}
                    style={{
                      height: "4rem",
                      marginLeft: "0.25rem"
                    }}
                  />
                </div>

              </div>

              :

                <img
                  src={titleImages[props.productName]}
                  style={{
                    height: "6.25rem",
                  }}
                />

          }


          <div
            style={{
              fontSize: "2.25rem",
              display: "flex",
              flexGrow: "1",
              marginTop: props.productName.split(":")[0] === "Stratbox Premium" ? 0 : "2rem",
              // marginLeft: "5rem",
              minWidth: "37.5rem",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: strings.weights.light,
              color: strings.colors.textPrimary,
              flexWrap: "nowrap",
              border: showBorders? "solid 1px lightseagreen" : "none",
            }}
          >
            {productData[props.productName].headline}
          </div>

        </div>



        {/*LICENSE ROW*/}
        <div
          style={{
            marginTop: "2.25rem",
            height: "3.875rem",
            padding: "0rem 0.5rem",
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: "nowrap",
            // border: "solid 1px grey",
          }}
        >

          {/*SELECTOR*/}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              minWidth: "20rem",
              width: "20rem",
              // border: "solid 1px blue"
            }}
          >

            {/*LABEL*/}
            <div
              style={{
                fontSize: "1.25rem",
                lineHeight: "1.25rem",
                color: strings.colors.textPrimary,
                fontWeight: strings.weights.medium,
              }}
            >
              License type
            </div>

            {/*DROPDOWN*/}
            <div
              className={"custom-select"}
              style={{
                // width: "200px",
              }}
            >

              <select
                name={"customerType"}
                id={"customerType"}
                defaultValue={"business"}
                value={selectedCustomerType}
                style={{
                  marginLeft: "1.25rem",
                  fontSize: strings.sizes.iconSecondary,
                  fontWeight: strings.weights.regular,
                  color: strings.colors.textTertiary,
                  border: "none",
                  borderRadius: "0.35rem",
                  padding: "0.5rem  2rem 0.5rem 1rem",
                  boxShadow: "1px 1px 5px 0px rgb(0 0 0 / 10%)",
                  cursor: "pointer"
                }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setCustomerType(e.target.value as CustomerType);
                }}
              >
                <option
                  style={{
                    userSelect: "none"
                  }}
                  value={"enterprise"}
                >Enterprise
                </option>

                <option
                  style={{
                    userSelect: "none"
                  }}
                  value={"business"}
                >Business
                </option>

                <option
                  style={{
                    userSelect: "none"
                  }}
                  value={"academia"}
                >Academia
                </option>

              </select>
            </div>
          </div>


          {/*LICENCE INFO*/}
          <div
            style={{
              // border: showBorders? "solid 1px lightseagreen" : "none",
              // border: "solid 1px lightseagreen",
              height: "100%",
              flexGrow: "2",
              // paddingLeft: "1rem",
              minWidth: "31.25rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            {/*MAIN*/}
            <div
              style={{
                // fontSize: strings.sizes.textPrimary,
                fontSize: "1rem",
                color: strings.colors.textPrimary,
                fontWeight: strings.weights.medium,
              }}
            >
              {licenseData[selectedCustomerType].headline}

            </div>

            {/*CAVEAT*/}
            {selectedCustomerType === "academia" && !props.isEdu && props.userDetails !== null && props.userDetails !== undefined?

              <div
                style={{
                  fontSize: "0.875rem",
                  color: strings.colors.actionNegative,
                  fontWeight: strings.weights.regular,
                  textAlign: "left",
                }}
              >
                * Academia pricing is only available for users at educational institutions. <br/>If you believe this is in error, please&nbsp;
                <a
                  style={{
                    fontSize: "0.875rem",
                    textDecoration: "none",
                  }}
                  href={"mailto:support@imagedreality.com"}
                >
                  contact us
                </a>
              </div>
              :
              <div
                style={{
                  fontSize: "0.875rem",
                  color: strings.colors.textSecondary,
                  fontWeight: strings.weights.regular,
                }}
              >
                {licenseData[selectedCustomerType].caveat}
              </div>
            }

          </div>

          {/*INTERVAL TOGGLE*/}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // border: "solid 1px green"
            }}
          >
            <div
              className="toggle-switch"
              style={{
                // border: "solid 1px black",
                display: "flex",
                flexGrow: "1",
                alignItems: "center",
                justifyContent: "flex-end",
                opacity: existingInterval !== undefined || selectedCustomerType === "enterprise"? "0.35" : 1,
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "5.5rem",
                  fontSize: "1.25rem",
                  lineHeight: "1.25rem",
                  color: strings.colors.textPrimary,
                  fontWeight: interval === 'month'? strings.weights.medium : strings.weights.light
                }}
              >
                Monthly
              </span>

              <button
                className="switch"
                style={{
                  padding: "0rem",
                  outline: "none",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: strings.colors.backgroundSecondary,
                  border: "none",
                }}
                onClick={ ()=> {

                  if (existingInterval !== undefined) {
                    window.alert("Your cart can only contain products with one type of billing frequency at a time.")
                    return;
                  }

                  interval === 'year'?
                    setInterval( 'month')
                    : setInterval( 'year');
                }}
              >
                <span
                  className="material-icons"
                  style={{
                    color: strings.colors.action,
                    // color: interval === 'year'? strings.colors.action : strings.colors.backgroundPrimary,
                    // backgroundColor: interval === 'year'? strings.colors.backgroundPrimary : strings.colors.action,
                    // border: interval === 'month'? `solid 0.125rem ${strings.colors.backgroundPrimary}` : `solid 0.125rem ${strings.colors.action}`,
                    fontSize: "3rem",
                    lineHeight: "3rem",
                  }}
                >
                  {interval === 'year' ? 'toggle_on' : 'toggle_off'}
                </span>
              </button>

              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "4.25rem",
                  fontSize: "1.25rem",
                  lineHeight: "1.25rem",
                  color: strings.colors.textPrimary,
                  fontWeight: interval === 'year' ? strings.weights.medium : strings.weights.light
                }}
              >
                  Yearly
              </span>
            </div>

            <div
              style={{
                width: "4.25rem",
                height: "1.175rem",
                backgroundColor: strings.colors.discount,
                borderRadius: "0.25rem",
                color: strings.colors.textPrimary,
                fontSize: strings.sizes.textSecondary,
                fontWeight: strings.weights.medium,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "flex-end",
                opacity: selectedCustomerType === "enterprise"? "0" : "1",
              }}
            >
              Save 25%
            </div>

          </div>
        </div>

        {/*TABLE & PRICE ROW*/}
        <div
          style={{
            marginTop: "1.75rem",
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "nowrap",
            border: showBorders? "solid 1px lightseagreen" : "none",
          }}
        >

          <ProductTable product={props.productName} />


          {/*PRICE*/}
          <div
            style={{
              width: "23rem",
              minWidth: "23rem",
              height: "12.5rem",
              backgroundColor: strings.colors.backgroundPrimary,
              display: "flex",
              flexDirection: "column",
              border: `solid 0.125rem ${strings.colors.backgroundTertiary}`,
              borderRadius: "0.35rem",
              margin: "0.5rem",
              padding: "0rem 1.5rem",
              justifyContent: "space-between",
            }}
          >

            {/*TOP ROW*/}
            <div
              style={{
                marginTop: "1.5rem",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: "1.25rem",
                  fontWeight: strings.weights.regular,
                  lineHeight: "1.25rem",
                  color: strings.colors.textPrimary
                }}
              >
                {props.productName.split(":")[0]}
              </div>

              <div
                style={{
                  fontSize: "1.25rem",
                  fontWeight: strings.weights.medium,
                  lineHeight: "1.25rem",
                  color: strings.colors.textPrimary
                }}
              >
                &nbsp;&nbsp;{selectedCustomerType.charAt(0).toUpperCase() + selectedCustomerType.slice(1)}
              </div>

            </div>

            {/*MIDDLE ROW*/}
            {selectedCustomerType === "enterprise" as CustomerType?
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >


                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "1rem",
                      fontWeight: strings.weights.regular,
                      lineHeight: "1rem",
                      textAlign: "center",
                      color:  strings.colors.textPrimary,
                    }}
                  >
                    To enquire about pricing and schedule a demo - get in touch
                  </div>
                </div>
              </div>
              :
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >

                {/*PRICE*/}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                  }}
                >

                  {matchingOffer === undefined || matchingPrice === undefined?
                    <div
                      className={"spinner"}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "2rem",
                        fontWeight: strings.weights.light,
                        lineHeight: "2rem",
                        color:  strings.colors.textPrimary,
                      }}
                    >
                    </div>
                    :
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "2rem",
                        fontWeight: strings.weights.light,
                        lineHeight: "2rem",
                        color:  strings.colors.textPrimary,
                      }}
                    >
                      ${(matchingPrice.unit_amount / 100 * quantity).toLocaleString()}
                    </div>
                  }

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "1rem",
                      fontWeight: strings.weights.light,
                      lineHeight: "1rem",
                      color:  strings.colors.textPrimary,
                    }}
                  >
                    per {interval}
                  </div>

                </div>

                {/*SEATS*/}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                  }}
                >

                  <div
                    style={{
                      fontSize: "1rem",
                      fontWeight: strings.weights.regular,
                      lineHeight: "1rem",
                      color:  strings.colors.textPrimary,
                    }}
                  >
                    Seats:
                  </div>

                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "1rem",
                      lineHeight: "1rem",
                      width: "1.25rem",
                      fontWeight: strings.weights.medium,
                      color:  strings.colors.textPrimary,
                      userSelect: "none",
                    }}
                  >
                    &nbsp;{quantity}
                  </div>

                  <div
                    style={{
                      marginLeft: "0.5rem",
                      width: "1.5rem",
                      height: "1.5rem",
                      backgroundColor: strings.colors.action,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.25rem",
                      // opacity: quantity === 5 ? "0.5" : 1,
                    }}
                    onClick={ () => {
                      // if (quantity === 5 && !props.isBiz) return;
                      setQuantity( quantity + 1);
                    }}
                  >


                    <div
                      className={"material-icons"}
                      style={{
                        lineHeight: "1.25rem",
                        textAlign: "center",
                        display: "flex",
                        fontSize: "1.25rem",
                        fontWeight: strings.weights.regular,
                        color: strings.colors.backgroundPrimary,
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      add
                    </div>
                  </div>

                  <div
                    style={{
                      marginLeft: "0.5rem",
                      width: "1.5rem",
                      height: "1.5rem",
                      backgroundColor: strings.colors.action,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.25rem",
                      opacity: quantity === 1 ? "0.5" : 1,
                    }}
                    onClick={ () => {
                      setQuantity( quantity === 1? 1 : quantity - 1);
                    }}
                  >
                    <div
                      className={"material-icons"}
                      style={{
                        lineHeight: "1rem",
                        textAlign: "center",
                        display: "flex",
                        fontSize: "1rem",
                        fontWeight: strings.weights.regular,
                        color: strings.colors.backgroundPrimary,
                        cursor: "pointer",
                        userSelect: "none",
                      }}

                    >
                      remove
                    </div>

                  </div>

                </div>

              </div>
            }


            {/*BOTTOM ROW*/}
            {selectedCustomerType === "enterprise" as CustomerType?
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "1.25rem"
                }}
              >


                {/*<a href={"https://www.imagedreality.com/contact/"} target="blank"*/}
                {/*   style={{*/}
                {/*     display: "flex",*/}
                {/*     fontSize: "1rem",*/}
                {/*     justifyContent: "center",*/}
                {/*     fontWeight: strings.weights.medium,*/}
                {/*     color: strings.colors.textPrimary,*/}
                {/*     backgroundColor: colors[2],*/}
                {/*     borderRadius: "0.35rem",*/}
                {/*     border: "solid 0.065rem black",*/}
                {/*     cursor: "pointer",*/}
                {/*     padding: "0.35rem 1rem",*/}
                {/*     userSelect: "none",*/}
                {/*     textDecoration: "none",*/}
                {/*     marginLeft: "1rem",*/}
                {/*     minWidth: "8rem",*/}
                {/*   }}*/}
                {/*>*/}
                {/*  Contact us*/}
                {/*</a>*/}
                <a
                  href={"https://www.imagedreality.com/contact/"} target="blank"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "1.125rem",
                    // lineHeight: "1.125rem",
                    width: "10rem",
                    height: "2.063rem",
                    fontWeight: strings.weights.regular,
                    color: strings.colors.backgroundPrimary,
                    backgroundColor: strings.colors.actionNeutral,
                    borderRadius: "0.8125rem",
                    cursor: "pointer",
                    userSelect: "none",
                    justifySelf: "flex-end",
                    alignSelf: "flex-end",
                    padding: "0.35rem 1rem",
                    textDecoration: "none"
                  }}
                >
                  Contact us
                </a>
              </div>
              :
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "1.25rem"
                }}
              >
                {matchingOffer === undefined || matchingPrice === undefined?

                  <div
                    className={"spinner"}>
                  </div>
                  :
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "1.125rem",
                      width: "10rem",
                      height: "2.063rem",
                      fontWeight: strings.weights.regular,
                      color: strings.colors.backgroundPrimary,
                      backgroundColor: strings.colors.actionNeutral,
                      borderRadius: "0.8125rem",
                      cursor: "pointer",
                      userSelect: "none",
                      justifySelf: "flex-end",
                      alignSelf: "flex-end",
                      padding: "0.35rem 1rem",
                      opacity: selectedCustomerType === "academia"?
                        props.userDetails === undefined
                        || props.userDetails === null
                        || props.isEdu? "1.0" : "0.25" : "1"
                    }}

                    onClick={ async () => {

                      if (selectedCustomerType === "academia" && props.userDetails !== null && props.userDetails !== undefined && !props.isEdu) {
                        console.log("SOMEHOW USER IS NOT EDU BUT WAS ABLE TO ADD TO CART...");
                        return
                      }

                      if (matchingOffer === undefined) {
                        console.log("NO MATCHING OFFER");
                        return;
                      }

                      if (matchingPrice === undefined) {
                        console.log("NO MATCHING PRICE")
                      }

                      else {
                        await addToCart(
                          matchingOffer.images[0],
                          matchingOffer.name,
                          matchingOffer.id,
                          matchingPrice.id,
                          quantity,
                          interval,
                          matchingPrice.unit_amount,
                          matchingOffer.metadata.type,
                        );
                      }
                    }}
                  >
                    Add to cart
                  </div>
                }
              </div>
            }

          </div>


        </div>


        {/*FEATURE & IMAGE ROW*/}
        <div
          style={{
            marginTop: "2.5rem",
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "nowrap",
            border: showBorders? "solid 1px lightseagreen" : "none",
          }}
        >

          {/*FEATURE LIST*/}
          <div
            style={{
              // width: "40%",
              minWidth: "22rem",
              border: showBorders? "solid 1px lightseagreen" : "none",
            }}
          >

            <ul
              style={{
                padding: "0rem",
                display: "flex",
                flexDirection: "column",
                // width: "100%",
                paddingLeft: "2rem",
                // margin: "0rem",
                // marginLeft: "0rem",
                // marginRight: "0rem",
                // marginBottom: "0rem",
                marginTop: "0.5rem",
                // minHeight: "8rem",
                // maxHeight: "8rem",
                overflow: "hidden",
              }}
            >
              {productData[props.productName].features.map(feature => (
                <li
                  style={{
                    fontSize: "1.25rem",
                    lineHeight: "1.75rem",
                    fontWeight: strings.weights.regular,
                    color: strings.colors.textPrimary,
                    listStylePosition: "outside",
                  }}
                >
                  {feature}
                </li>
                )
              )}
            </ul>
          </div>

          {/*IMAGE*/}

          {props.productName.split(":")[0] !== "Stratbox Suite" &&
            props.productName.split(":")[0] !== "Stratbox Premium" &&
              <div
                style={{
                  border: `solid 0.125rem ${strings.colors.backgroundTertiary}`,
                  borderRadius: "0.15rem",
                  marginLeft: "3rem",
                  // display: "flex",
                  // justifyContent: "center",
                }}
              >

                <img
                  src={previewImages[props.productName]}
                  style={{
                    height: "18.75rem",
                    borderRadius: "0.15rem",
                  }}

                />
              </div>
          }


        </div>


      </div>

    </div>
  )
}

export default ProductPage;