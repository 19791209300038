import React, { useEffect, useState } from "react";
import {getOfferings, getSubscriptions} from "../shared/merchant";
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from "react-router-dom";
import { checkCart } from "../shared/cart";
import SubscriptionCard from "../components/SubscriptionCard";
import strings from "../strings.json";
import {getUser} from "../shared/authentication";
import edu_domains from "../edu_domains.json";

const stripe_pk: string = "pk_test_51ONDFZBu6k6cGFaYIK8W3B3EuGQSu7u7p6A9QjdXW6riB8JVAcOD0fXWu9nJhNq4yNB2eLLy6h9SpHTDTdCaGgGi00riGzq9ql";

interface subscription {
    current_period_start: number,
    current_period_end: number,
    id: string,
    plan: {
        id: string
        amount: number,
        interval: string,
        product: string
    },
    items: {
        data: [subscriptionItem]
    },
    quantity: number,
}

interface subscriptionItem {
    id: string,
    plan: {
        id: string,
        product: string,
        currency: string,
    }
}

interface product {
    id: string,
    object: string,
    active: boolean,
    attributes: any[],
    created: number,
    default_price: string,
    description: string,
    features: any[],
    images: string[],
    livemode: boolean,
    metadata: object,
    name: string,
    package_dimensions: any,
    shippable: any,
    statement_descriptor: any,
    tax_code: string,
    type: string,
    unit_label: any,
    updated: number,
    url: any
    prices: price[];
}

interface price {
    id: string,
    object: string,
    active: boolean,
    billing_scheme: string,
    created: number,
    currency: string,
    custom_unit_amount: any,
    livemode: boolean,
    lookup_key: any,
    metadata: object,
    nickname: any,
    product: any,
    recurring: {
        aggregate_usage: any,
        interval: any,
        interval_count: number,
        trial_period_days: any,
        usage_type: string
    },
    tax_behavior: string,
    tiers_mode: any,
    transform_quantity: any,
    type: string,
    unit_amount: number,
    unit_amount_decimal: string
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe(stripe_pk);

const Account = (props: any) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState<subscription[]>();
    const [offers, setOfferings] = useState<product[]>([]);
    const [message, setMessage] = useState("No subscriptions found.")
    useEffect(()=> {
        document.title = 'My Account | Stratbox';
    }, []);

    // useEffect(()=>{
    //     if (props.userDetails === undefined || props.userDetails === null) {
    //         setTimeout(() => {
    //             console.log("account.tsx redirect to /login")
    //             navigate("/login");
    //         }, 500);
    //     }
    // }, [props.userDetails]);

    useEffect(() => {

        if (props.userDetails === undefined || props.userDetails === null) {

            try {
                setLoading(true);
                getUser().then(({error, payload}) => {

                    if (error !== null) {
                        props.setCheckAuthResult(false);
                        setLoading(false);
                        navigate("/plans");
                    }
                    else {
                        props.setUserDetails(payload.payload);
                        props.setCheckAuthResult(true);
                        setLoading(false);

                        // Check if the user has a valid edu email:
                        if (edu_domains.includes(payload.payload.email.split("@")[1])) {
                            console.log(`${payload.payload.email.split("@")[1]} is a valid edu domain`);
                            props.setIsEdu(true);
                        }

                        if (payload.payload.role === "admin") {
                            setLoading(true);

                            try {
                                getSubscriptions(payload.payload.email).then((res) => (res.json())
                                    .then((data) => {
                                        console.log(data);

                                        if (data.error !== null) {
                                            setLoading(false);


                                        } else {
                                            // setSubscriptions(data.subscriptions.data[0].items.data);
                                            setSubscriptions(data.subscriptions.data);
                                            setLoading(false);

                                            console.log(subscriptions);
                                        }
                                    })
                                );
                            } catch (e) {
                                console.log("Failed to fetch user subscriptions. They may not be a Stripe customer.");
                                setLoading(false);
                            }
                        }

                        else {
                            setLoading(false);
                            setMessage("Subscriptions are managed by your organisation administrator.")
                        }

                    }
                });

            }
            catch (e) {
                console.log("Error while attempting to fetch user details on the My Account page...");
                console.log(e);

                setLoading(false);
                navigate("/plans");
            }
        }

        setLoading(true);
        getOfferings().then((res) => (res.json())
            .then((data) => {
                console.log(data.offerings);
                setOfferings(data.offerings);
                setLoading(false);
            })
        );

        if (props.userDetails !== undefined && props.userDetails.role === "admin") {
            setLoading(true);

            try {
                getSubscriptions(props.userDetails.email).then((res) => (res.json())
                    .then((data) => {
                        console.log(data);

                        if (data.error !== null) {
                            setLoading(false);

                        }
                        else {
                            setSubscriptions(data.subscriptions.data);
                            setLoading(false);
                            console.log(subscriptions);
                        }
                    })
                );
            }
            catch (e) {
                console.log("Failed to fetch user subscriptions. They may not be a Stripe customer.");
                setLoading(false);
            }
        }

        else if (props.userDetails !== undefined && props.userDetails !== null && props.userDetails.role === "user") {
            setLoading(false);
            setMessage("Subscriptions are managed by your organisation administrator");

        }
    }, []);


    return (

        <div
            id={"account"}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: strings.colors.backgroundPrimary,
                height: "100%",
            }}
        >
            <div
                style={{
                    fontSize: strings.sizes.iconSecondary,
                    fontWeight: strings.weights.regular,
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    userSelect: "none",
                }}
            >
                My Subscriptions
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    paddingTop: "2rem",
                    width: "100%",
                    flexGrow: 2,
                    flexWrap: "wrap",
                    backgroundColor: "#f8f8f8"
                }}
            >
                {subscriptions !== undefined && subscriptions.length > 0?

                    subscriptions.map(subscription => {
                        return (
                            subscription.items.data.map(subscriptionItem => {
                                return (
                                    <SubscriptionCard
                                        offer={offers.filter(offer => offer.id === subscriptionItem.plan.product)[0]}
                                        quantity={subscription.quantity}
                                        subscription={subscriptionItem}
                                        userDetails={props.userDetails}
                                        current_period_end={subscription.current_period_end}
                                        subscription_id={subscription.id}
                                    />
                                )

                            })
                        )
                    })
                    :
                        <div

                        >

                            {loading? <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                            style={{
                                backgroundColor: strings.colors.actionNeutral
                            }}


                        />
                        : <span>{message}</span>}

                        </div>

                }
            </div>
        </div>
    )
}


export default Account;