import strings from "../strings.json";
import { useEffect, useState } from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {checkCart} from "../shared/cart";

const Cart = (props) => {

    const [cart, setCart] = useState(localStorage.getItem("cart"));
    const [quantity, setQuantity] = useState(0);
    const [flash, doFlash] = useState(false);

    const navigate = useNavigate();

    function flashCartIcon() {
        doFlash(true)
        setTimeout(() => {
            doFlash(false);
        }, 100)
    }

    function handleQuantityChange(event, itemKey) {
        console.log(`New quantity for ${itemKey}: ${event.target.value}`);

        let updatedCart = cart;

        if (updatedCart.hasOwnProperty(itemKey)) {
            updatedCart[itemKey].quantity = event.target.value;
        }

        localStorage.setItem("cart", JSON.stringify(updatedCart));
        window.dispatchEvent(new Event('storage'));
    }

    function checkCartQuantity() {

        let thisCart = localStorage.getItem("cart");

        if (thisCart !== null) {

            thisCart = JSON.parse(thisCart);

            let counter = 0;
            Object.entries(thisCart).forEach(([cartItemKey, cartItem]) => {
                // console.log(cartItem);

                //doflash
                counter += Number(cartItem.quantity);
            });

            setQuantity(counter);
            setCart(thisCart);
        }
    }

    useEffect( () => {

        checkCartQuantity();

        const onStorage = () => {
            console.log("onStorage()");

            flashCartIcon();
            checkCartQuantity();
        }

        window.addEventListener('storage', onStorage);
        console.log("added");

        return () => {
            window.removeEventListener('storage', onStorage);
            console.log("removed");
        };
    }, []);


    return (

        <div
            style={{
                position: "fixed",
                top: "0",
                right: "0",
                width: props.cartOpen? "22rem" : "0rem",
                // width: "22rem",
                height: "100%",
                backgroundColor: strings.colors.backgroundPrimary,
                boxShadow: "-1px 0px 10px #c3c3c3",
                zIndex: 10,
                cursor: "default",

            }}
        >

            {!props.cartOpen &&
                <div
                    style={{
                        marginTop: "0rem",
                        width: "3.75rem",
                        padding: "0.25rem",
                        height: "3rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // backgroundColor: "rgba(51,51,51,0.15)",
                        // border: "solid 1px black",
                        boxShadow: flash? "0 0 15px #42a5f5, 0 0 30px #42a5f5, 0 0 45px #42a5f5" : "none",
                        cursor: "pointer",
                        transform: !props.cartOpen? "translateX(-3.75rem)" : "none",
                    }}
                    onClick={ () => {
                        console.log("setexpanded");
                        props.setAvatarMenuOpen(false);
                        props.setCartOpen(!props.cartOpen)
                    }}
                >
                    <div
                      style={{
                          width: "2rem",
                          marginRight: "0.25rem",
                          // margin: "auto",
                          // height: "1.85rem",
                          height: "2rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: strings.colors.action,
                          color: strings.colors.backgroundPrimary,
                          borderRadius: "0.25rem"
                      }}
                    >

                        <div
                            className={'material-icons'}
                            style={{
                                color: strings.colors.backgroundPrimary,
                                fontSize: strings.sizes.iconPrimary,
                                // lineHeight: "1.25rem",
                                textDecoration: "none",
                                WebkitTouchCallout: "none",
                                WebkitUserSelect: "none",
                                htmlUserSelect: "none",
                                MozUserSelect: "none",
                                msUserSelect: "none",
                                userSelect: "none",
                                zIndex: "10",
                            }}
                        >
                            shopping_cart
                        </div>

                        <div
                            style={{
                                top: "0.5rem",
                                right: "1.125rem",
                                position: "absolute",
                                marginLeft: "0.125rem",
                                display: quantity > 0? "block" : "none",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "1.5rem",
                                color: strings.colors.actionNegative,
                                height: "100%",
                                fontWeight: strings.weights.bold,
                                userSelect: "none",
                            }}
                        >
                            .
                        </div>
                    </div>

                    {/*<div*/}
                    {/*    style={{*/}
                    {/*        marginLeft: "0.125rem",*/}
                    {/*        display: quantity > 0? "flex" : "none",*/}
                    {/*        justifyContent: "center",*/}
                    {/*        alignItems: "center",*/}
                    {/*        fontSize: "1rem",*/}
                    {/*        color: strings.colors.brandPrimary,*/}
                    {/*        height: "100%",*/}
                    {/*        fontWeight: strings.weights.bold,*/}
                    {/*        userSelect: "none",*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {quantity}*/}
                    {/*</div>*/}
                </div>
            }

            {props.cartOpen &&

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",

                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            // backgroundColor: "rgba(253,251,132,0.2)",
                            height: "3rem",
                            // width: "3.75rem",
                            // padding: "0.25rem",
                            // border: "solid 1px black",
                            // padding: "0.5rem 1rem",
                        }}
                    >

                        <div
                            style={{
                                fontSize: strings.sizes.textPrimary,
                                lineHeight: strings.sizes.textPrimary,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                marginLeft: "3.75rem",
                                flexGrow: "1",
                                // backgroundColor: "#333333",
                                color: strings.colors.textPrimary,
                                fontWeight: strings.weights.medium,
                                verticalAlign: "top",
                            }}
                        >
                            Summary
                        </div>



                        <div
                            style={{
                                width: "3.75rem",
                                height: "3rem",
                                justifyContent: "center",
                                alignItems: "center",
                                color: strings.colors.backgroundPrimary,
                                cursor: "pointer",
                                // backgroundColor: "rgba(51,51,51,0.15)",
                                // transform: "translateX(0rem) translateY(-0.5rem)",
                                display: "flex",
                            }}
                            onClick={ () => {
                                console.log("setexpanded");

                                props.setAvatarMenuOpen(false);
                                props.setCartOpen(!props.cartOpen)
                            }}
                        >

                            <div
                              style={{
                                  width: "2rem",
                                  marginRight: "0.25rem",
                                  // margin: "auto",
                                  // height: "1.85rem",
                                  height: "2rem",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: strings.colors.action,
                                  color: strings.colors.backgroundPrimary,
                                  borderRadius: "0.25rem"
                              }}
                            >

                            <div
                                className={'material-icons'}
                                style={{
                                    color: strings.colors.backgroundPrimary,
                                    fontSize: "1.75rem",
                                    lineHeight: "1.75rem",
                                    textAlign: "center",
                                }}
                            >
                                chevron_right
                            </div>

                            </div>

                        </div>

                    </div>

                    <div
                        style={{
                            marginLeft: "1rem",
                            marginRight: "1rem",
                            marginBottom: "0.5rem",
                            marginTop: "0.5rem",
                            height: "0.0875rem",
                            width: "90%",
                            backgroundColor: strings.colors.backgroundTertiary,
                        }}
                    >
                    </div>


                    {cart !== null && cart !== undefined && Object.entries(cart).map(([itemKey, item], index) => {
                        console.log(item);

                        return (

                            <div
                                className={"cartItemRow"}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    padding: "0.5rem 1rem",
                                    userSelect: "none",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        justifyContent: "center",
                                    }}
                                >

                                    <img
                                        src={item.productImage}
                                        style={{
                                            width: "auto",
                                            height: "1.5rem",
                                        }}
                                    />

                                    <div
                                        style={{
                                            flexGrow: "2",
                                            marginLeft: "0.675rem",
                                            lineHeight: "1.45rem",
                                            fontSize: strings.sizes.textPrimary,
                                            fontWeight: strings.weights.bold,
                                        }}
                                    >
                                        {item.productName}
                                    </div>

                                    <div
                                        className={'material-icons cartItemRemove'}
                                        style={{
                                            color: strings.colors.actionNegative,
                                            fontSize: "1rem",
                                            lineHeight: "1rem",
                                            textDecoration: "none",
                                            WebkitTouchCallout: "none",
                                            WebkitUserSelect: "none",
                                            htmlUserSelect: "none",
                                            MozUserSelect: "none",
                                            msUserSelect: "none",
                                            userSelect: "none",
                                            cursor: "pointer",
                                            border: "solid 0.0675rem red",
                                            borderRadius: "0.125rem",
                                            alignSelf: "flex-end",
                                            marginBottom: "0.15rem",
                                        }}
                                        onClick={ () => {

                                            let updatedCart = cart;

                                            if (updatedCart.hasOwnProperty(itemKey)) {
                                                delete updatedCart[itemKey];
                                            }

                                            localStorage.setItem("cart", JSON.stringify(updatedCart));
                                            window.dispatchEvent(new Event('storage'))

                                        }}
                                    >
                                        close
                                    </div>

                                </div>

                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "flex-start",
                                        width: "100%",
                                        marginTop: "0.65rem",
                                    }}
                                >

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                marginLeft: "0.35rem",
                                                color: strings.colors.textTertiary,
                                                fontWeight: strings.weights.medium,
                                                fontSize: strings.sizes.textSecondary,
                                            }}
                                        >
                                            Seats
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                marginTop: "0.25rem",
                                                marginLeft: "0.35rem",
                                            }}
                                        >

                                            <select
                                                style={{
                                                    fontSize: strings.sizes.textSecondary,
                                                    color: strings.colors.textPrimary,
                                                    fontWeight: strings.weights.medium,
                                                }}
                                                id="quantity"
                                                value={item.quantity}
                                                onChange={(event) => handleQuantityChange(event, itemKey)}
                                            >
                                                {[...Array(25).keys()].map((num) => (
                                                    <option key={num + 1} value={num + 1}>
                                                        {num + 1}
                                                    </option>
                                                ))}
                                            </select>

                                        </div>

                                    </div>


                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flexGrow: "2",
                                        }}
                                    >

                                        <div
                                            style={{
                                                textAlign: "right",
                                                fontSize: strings.sizes.textSecondary,
                                                color: strings.colors.textPrimary,
                                                fontWeight: strings.weights.medium,
                                            }}
                                        >
                                            ${item.cost / 100}.00/{item.interval} per seat
                                        </div>

                                        <div
                                            style={{
                                                textAlign: "right",
                                                fontSize: strings.sizes.textSecondary,
                                                color: strings.colors.textPrimary,
                                                fontWeight: strings.weights.medium,
                                            }}
                                        >
                                            excl. VAT
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    <div
                        style={{
                            marginLeft: "1rem",
                            marginRight: "1rem",
                            marginBottom: "0.5rem",
                            marginTop: "0.5rem",
                            height: "0.0875rem",
                            width: "90%",
                            backgroundColor: strings.colors.backgroundTertiary,
                        }}
                    >
                    </div>

                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            padding: "0.5rem 1rem",
                        }}
                    >

                        <div
                            style={{
                                fontSize: strings.sizes.textPrimary,
                                color: strings.colors.textPrimary,
                                fontWeight: strings.weights.medium
                            }}
                        >
                            Total:
                        </div>

                        <div
                            style={{
                                fontSize: strings.sizes.textPrimary,
                                color: strings.colors.textPrimary,
                                fontWeight: strings.weights.medium
                            }}
                        >
                            ${cart !== null && cart !== undefined && Object.entries(cart).length !== 0 && Object.values(cart).reduce((total, item) => total + (item.quantity * item.cost / 100), 0)}.00
                        </div>


                    </div>

                    { quantity > 0 && props.userDetails !== undefined ?
                        <Link
                            to="/checkout"
                            style={{
                                // backgroundColor: strings.colors.actionNeutral,
                                // color: strings.colors.backgroundPrimary,
                                // width: "12rem",
                                // height: "2rem",
                                // borderRadius: "0.125rem",
                                // display: "flex",
                                // justifyContent: "center",
                                // alignItems: "center",
                                // cursor: "pointer",
                                // textDecoration: "none",
                                // marginTop: "0.5rem",

                                marginTop: "1rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "1.125rem",
                                width: "12rem",
                                height: "2.063rem",
                                fontWeight: strings.weights.regular,
                                color: strings.colors.backgroundPrimary,
                                backgroundColor: strings.colors.actionNeutral,
                                borderRadius: "0.8125rem",
                                cursor: "pointer",
                                userSelect: "none",
                                padding: "0.35rem 1rem",
                                textDecoration: "none",
                            }}
                            onClick={ () => {
                                if (!props.isEdu) {
                                    checkCart();
                                }
                                console.log("setexpanded");
                                props.setCartOpen(false);

                            }}
                        >
                            Go to checkout
                        </Link>

                        :

                        quantity > 0 &&

                        <Link
                            to="/login?cart=true"
                            style={{
                                // backgroundColor: strings.colors.actionNeutral,
                                // color: strings.colors.backgroundPrimary,
                                // width: "12rem",
                                // height: "2rem",
                                // borderRadius: "0.125rem",
                                // display: "flex",
                                // justifyContent: "center",
                                // alignItems: "center",
                                // cursor: "pointer",
                                // textDecoration: "none",
                                // marginTop: "0.5rem",

                                marginTop: "1rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "1.125rem",
                                width: "12rem",
                                height: "2.063rem",
                                fontWeight: strings.weights.regular,
                                color: strings.colors.backgroundPrimary,
                                backgroundColor: strings.colors.actionNeutral,
                                borderRadius: "0.8125rem",
                                cursor: "pointer",
                                userSelect: "none",
                                padding: "0.35rem 1rem",
                                textDecoration: "none",
                            }}
                            onClick={ () => {
                                console.log("setexpanded");
                                props.setCartOpen(false)
                            }}
                        >
                            Go to checkout
                        </Link>
                    }
                </div>
            }
        </div>
    )
}


export default Cart;