
// interface product = {
//
// }
export async function addToCart(
    productImage: string,
    productName: string,
    productId: string,
    priceId: string,
    quantity: number,
    interval: string,
    cost: number,
    customerType: string,
) {

    console.log(`User added ${quantity} seats of ${productName} to cart on a ${interval} basis. It has product id = ${productId} and price id = ${priceId}. The cost is ${cost * quantity / 100}`);

    let existing = localStorage.getItem("cart");
    let existingCart;


    if (existing !== null) {
        existingCart = JSON.parse(existing);
    }
    else {
        console.log("Cart is not yet defined...");

        const newCart = {
            [priceId]: {
                productImage: productImage,
                productName: productName,
                productId: productId,
                quantity: quantity,
                interval: interval,
                cost: cost,
                customerType: customerType
            }
        }

        localStorage.setItem("cart", JSON.stringify(newCart));
        window.dispatchEvent(new Event('storage'))
        return;
    }

    console.log("Existing cart:");
    console.log(existingCart);

    console.log(Object.keys(existingCart));


    if (Object.keys(existingCart)?.includes(priceId)) {
        console.log("Found this exact product already in the cart...");
        console.log(`with quantity = ${existingCart[priceId].quantity}`);

        let updatedCart = existingCart;
        updatedCart[priceId].quantity += quantity;

        localStorage.setItem("cart", JSON.stringify(updatedCart));
        window.dispatchEvent(new Event('storage'))
        return;
    }

    else {
        console.log("This product not yet in the cart...");

        let updatedCart = existingCart;

        updatedCart[priceId] = {
            productImage: productImage,
            productName: productName,
            productId: productId,
            quantity: quantity,
            interval: interval,
            cost: cost,
            customerType: customerType
        }


        localStorage.setItem("cart", JSON.stringify(updatedCart));
        window.dispatchEvent(new Event('storage'))
        return;
    }

}


export function checkCart() {
    let thisCart = localStorage.getItem("cart");

    if (thisCart !== null) {

        let cartItems = JSON.parse(thisCart);
        let updatedCart: { [x: string]: any; } = {}
        let notifyFlag = false;

        Object.entries(cartItems).forEach(([cartItemKey, cartItem]) => {
            // @ts-ignore
            if (cartItem.customerType !== "academia") {
                console.log(cartItem);
                updatedCart[cartItemKey] = cartItem
            }
            else {
                notifyFlag = true;
                console.log(cartItemKey);
            }
        });

        localStorage.setItem("cart", JSON.stringify(updatedCart));
        window.dispatchEvent(new Event('storage'))

        if (notifyFlag) {
            window.alert("Your cart contained products for Academia users, which are not compatible with your account type. These items have been removed.")
        }

        return notifyFlag;
    }
}