// © Imaged Reality Ltd 2022. All Rights Reserved
// import irLogo from '../img/ir_logo.png';
import strings from '../strings.json';

const Footer = (props: any) => {
    return(
      <div
        style={{
          minHeight: "4.5rem",
          height: "4.5rem",
          // position: "absolute",
          bottom: "0",
          width: "100%",
          backgroundColor: strings.colors.backgroundPrimary,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >

        <img
          src={"ir_logo.png"}
          style={{
            height: "1.25rem",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href = "https://imagedreality.com";
          }}
        >
        </img>

      </div>
    );
}

export default Footer;