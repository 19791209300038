import {
    useEffect,
    useState
} from "react";

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate, useNavigate
} from "react-router-dom";
import strings from "../strings.json";

const Return = () => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        fetch(
            `https://api.analytics.imagedreality.com/store/session-status?session_id=${sessionId}`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include'
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setStatus(data.status);
                setCustomerEmail(data.customer_email);
            });
    }, []);

    if (status === 'open') {
        navigate("/checkout");
    }

    else if (status === 'complete') {

        localStorage.setItem("cart", "{}");
        window.dispatchEvent(new Event('storage'));

        return (
            <section id="success">
                <div
                    style={{
                        marginTop: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: strings.weights.medium,
                        color: strings.colors.textPrimary,
                        fontSize: strings.sizes.textPrimary,
                    }}
                >
                    <div
                        style={{
                            fontSize: strings.sizes.iconSecondary,
                            color: strings.colors.textPrimary,
                            fontWeight: strings.weights.regular,
                        }}
                    >
                        Your Stratbox purchase was successful - We appreciate your business!
                    </div>
                    <div
                        style={{
                            marginTop: "1rem",
                            fontSize: strings.sizes.iconSecondary,
                            color: strings.colors.textPrimary,
                            fontWeight: strings.weights.medium,
                        }}
                    >
                        You can now proceed to&nbsp;
                        <a
                            href={"https://auth.stratbox.imagedreality.com"}
                            style={{
                                fontSize: strings.sizes.iconSecondary,
                                fontWeight: strings.weights.medium,
                            }}
                        >
                            sign in
                        </a>
                        &nbsp;and start using Stratbox
                    </div>
                    <div
                        style={{
                            fontSize: strings.sizes.textSecondary,
                            color: strings.colors.textPrimary,
                            fontWeight: strings.weights.regular,
                            marginTop: "2rem",
                        }}
                    >
                        If you have any questions, please contact us via&nbsp;
                        <a
                            style={{
                                fontSize: strings.sizes.textSecondary,
                                fontWeight: strings.weights.regular,
                            }}
                            href="mailto:billing@imagedreality.com">billing@imagedreality.com
                        </a>
                    </div>
                </div>
            </section>
        )
    }

    else if (status === 'expired') {

        localStorage.setItem("cart", "{}");
        window.dispatchEvent(new Event('storage'));

        return (
            <section id="expired">
                <div
                    style={{
                        marginTop: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: strings.weights.medium,
                        color: strings.colors.textPrimary,
                        fontSize: strings.sizes.textPrimary,
                    }}
                >
                    <div
                        style={{
                            fontSize: strings.sizes.iconSecondary,
                            color: strings.colors.textPrimary,
                            fontWeight: strings.weights.regular,
                        }}
                    >
                        Your Stratbox purchase was not successful
                    </div>
                    <div
                        style={{
                            marginTop: "1rem",
                            fontSize: strings.sizes.iconSecondary,
                            color: strings.colors.textPrimary,
                            fontWeight: strings.weights.medium,
                        }}
                    >
                        Please try again: &nbsp;
                        <span
                            style={{
                                color: strings.colors.actionNeutral,
                                fontSize: strings.sizes.iconSecondary,
                                fontWeight: strings.weights.medium,
                                cursor: "pointer",
                            }}
                            onClick={() => navigate("/plans")}
                        >
                            back to store
                        </span>
                    </div>
                    <div
                        style={{
                            fontSize: strings.sizes.textSecondary,
                            color: strings.colors.textPrimary,
                            fontWeight: strings.weights.regular,
                            marginTop: "2rem",
                        }}
                    >
                        Still having problems with your purchase? Contact us via&nbsp;
                        <a
                            style={{
                                fontSize: strings.sizes.textSecondary,
                                fontWeight: strings.weights.regular,
                            }}
                            href="mailto:billing@imagedreality.com">billing@imagedreality.com
                        </a>
                    </div>
                </div>
            </section>
        )
    }

    return null;
}


export default Return;