import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import React from "react";
import { verify } from "../shared/utils";
import Cookies from "js-cookie";
import {checkCart} from "../shared/cart";
import strings from "../strings.json";
function Verify (props: any){

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [verifyData, setVerifyData] = React.useState(
        {
            isVerifying : false,
            verifyFetched : false,
            emailVerified:false,
            feedbackMessage : ""
        }
    );

    useEffect(() => {

        props.setUserDetails(undefined);

        console.log(searchParams.get('email') || "no email");
        console.log(searchParams.get('verification_code') || "no verification_code");


        if(verifyData.emailVerified) {
            console.log("redirecting to login page");
            setTimeout(() => {
                console.log("verify.tsx redirect to /login?cart=true")


                let has_cart_item = false;
                let cart = localStorage.getItem("cart");

                if (cart !== null) {
                    let parsedCart: { [key: string]: { quantity: number, customerType: string } } = JSON.parse(cart);
                    let has_cart_item = Object.entries(parsedCart).length > 0;

                    if (has_cart_item) {
                        navigate("/login?cart=true");
                    }
                }
                else {
                    navigate("/login?cart=false");
                }

            }, 2000);
        }
        else if(verifyData.isVerifying) {
            console.log("calling fetch verifying");
            verifyEmail();
        }
    });

    function renderVerifying(){
        return(
            <form className="form-verify" onSubmit={handleVerify}>
              <div
                className="login-title"
                style={{
                  fontSize: "1rem",
                  fontWeight: strings.weights.medium,
                  color: strings.colors.textPrimary,
                  marginBottom: "1rem",
                }}
              >Verify your email</div>
                <b>{searchParams.get('email')}</b>
                <p>Please confirm your email to start using Stratbox.</p>
                <button id="verify" className="btn btn-stbx-primary form-control">
                    Click here to verify
                </button>
            </form>)
    }

    function handleVerify(event: React.FormEvent<HTMLFormElement>){

        try {
            document.cookie = 'id_token' + '=; Path=/; Domain=.imagedreality.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            document.cookie = 'access_token' + '=; Path=/; Domain=.imagedreality.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            document.cookie = 'refresh_token' + '=; Path=/; Domain=.imagedreality.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            document.cookie = 'CloudFront-Signature' + '=; Path=/; Domain=.imagedreality.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            document.cookie = 'CloudFront-Policy' + '=; Path=/; Domain=.imagedreality.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            document.cookie = 'CloudFront-Key-Pair-Id' + '=; Path=/; Domain=.imagedreality.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
        catch (e) {
            console.log("Unable to clear all cookie values...");
            console.log(e);
        }

        event.preventDefault();
        console.log("verifying");
        setVerifyData(prevVerifyData=>{
            return {
                ...prevVerifyData,
                isVerifying:true
            }
        });
    }

    async function verifyEmail(){
        var email = searchParams.get('email') || "";
        var verification_code = searchParams.get('verification_code') || "";

        if(email && verification_code){
            const error = await verify(email,verification_code);
            if(error) {
                setVerifyData(prevVerifyData=>{
                    return {
                        ...prevVerifyData,
                        verifyFetched:true,
                        emailVerified:false,
                        isVerifying:false,
                        feedbackMessage : error
                    }
                });
            }
            else {
                setVerifyData(prevVerifyData=>{
                    return {
                        ...prevVerifyData,
                        verifyFetched:true,
                        emailVerified:true,
                        isVerifying:false,
                        feedbackMessage : "Thank you for verifying your email address. We'll redirect you to the login page shortly."
                    }
                });
            }
        }
        else {
            setVerifyData(prevVerifyData=>{
                return {
                    ...prevVerifyData,
                    verifyFetched:true,
                    emailVerified:false,
                    isVerifying:false,
                    feedbackMessage : "Bad or missing parameters."
                }
            });
        }
    }

    return(

      <div
        style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: strings.colors.backgroundSecondary,
            flexGrow: "2",
            paddingBottom: "1rem",
        }}
      >
        <div
            className="sc-login p-4"
            style={{marginTop: "4rem"}}
        >
            {verifyData.verifyFetched ?
                <div
                    className="verify-title"
                    style={{
                        fontSize: "1rem",
                        fontWeight: strings.weights.medium,
                        color: strings.colors.textPrimary,
                    }}
                >
                    {verifyData.feedbackMessage}
                </div>
                : verifyData.isVerifying ?
                    <div
                        className="login-title"
                        style={{
                          fontSize: "1rem",
                          fontWeight: strings.weights.medium,
                          color: strings.colors.textPrimary,
                        }}
                    >
                        Verification...
                    </div>
                    : renderVerifying()
            }
        </div>
      </div>
    );
}

export default Verify;