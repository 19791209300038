// © Imaged Reality Ltd 2022. All Rights Reserved
// import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "react-sidebar";
import strings from "../strings.json";
import { logout } from "../shared/authentication";
import {useNavigate} from "react-router-dom";





const AvatarMenu = (props) => {

    const navigate = useNavigate();

    function closeIt() {
        try {
            let overlay = document.getElementsByClassName("avatarOverlay");
            overlay[0].removeEventListener("click", closeIt(), false);
            props.setAvatarMenuOpen(false);
        }
        catch (e) {
            console.log(e)
        }
    }

    function onSetOpen() {
        try {
            let overlay = document.getElementsByClassName("avatarOverlay");
            overlay[0].removeEventListener("click", closeIt(), false);
            props.setCartOpen(false);
            props.setAvatarMenuOpen(true);
        }
        catch (e) {
            console.log(e)
        }
        try {
            let overlay = document.getElementsByClassName("avatarOverlay");
            overlay[0].addEventListener("click", closeIt(), false);
        }
        catch (e) {
            console.log(e)
        }
    }

    return (
        <Sidebar
            sidebar={
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        right: "0",
                        backgroundColor: strings.colors.backgroundPrimary,
                    }}
                >

                    {/* USERNAME */}
                    <div
                        style={{
                            fontSize: strings.sizes.textPrimary,
                            textAlign: "left",
                            fontWeight: strings.weights.regular,
                            float: "left",
                            width: "100%",
                            color: strings.colors.textPrimary,
                            padding: "1.25rem",
                            paddingBottom: "0.675rem",
                            textDecoration: "none",
                            WebkitTouchCallout: "none",
                            WebkitUserSelect: "none",
                            htmlUserSelect: "none",
                            MozUserSelect: "none",
                            msUserSelect: "none",
                            userSelect: "none",
                        }}
                        onClick={() => {
                            props.setAvatarMenuOpen(false);
                        }}
                    >
                        {props.userDetails?.first_name + " " + props.userDetails?.last_name}
                    </div>

                    {/* SEPARATOR */}
                    <div
                        style={{
                            height: "0.0675rem",
                            width: "100%",
                            backgroundColor: strings.colors.backgroundTertiary,
                        }}
                    >
                    </div>

                    {props.avatarMenuOpen ? (
                        <div
                            style={{
                                verticalAlign: "middle",
                                height: "auto",
                                WebkitScrollbar: "none",
                                color: strings.colors.textTertiary,
                            }}
                        >

                            {/*ADMINISTRATOR PANEL*/}
                            {props.userDetails !== undefined && props.userDetails !== null && props.userDetails.role === "admin" &&
                                <div
                                    className={'avatar-row-button'}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        padding: "0.6125rem 1.25rem",
                                        textDecoration: "none",
                                    }}
                                    onClick={() => {
                                        window.location.href = "https://auth.stratbox.imagedreality.com?enable-admin=true"
                                    }}
                                >

                                    <div
                                        className={'material-icons'}
                                        style={{
                                            opacity: "1",
                                            fontSize: "1.375rem",
                                            lineHeight: "1.375rem",
                                            textDecoration: "none",
                                            WebkitTouchCallout: "none",
                                            WebkitUserSelect: "none",
                                            htmlUserSelect: "none",
                                            MozUserSelect: "none",
                                            msUserSelect: "none",
                                            userSelect: "none",
                                            color: strings.colors.textTertiary,
                                        }}
                                    >
                                        admin_panel_settings
                                    </div>

                                    <div
                                        style={{
                                            fontSize: "0.875rem",
                                            fontWeight: "400",
                                            textAlign: "left",
                                            paddingLeft: "1.25rem",
                                            textDecoration: "none",
                                            WebkitTouchCallout: "none",
                                            WebkitUserSelect: "none",
                                            htmlUserSelect: "none",
                                            MozUserSelect: "none",
                                            msUserSelect: "none",
                                            userSelect: "none"
                                        }}
                                    >
                                        Administration
                                    </div>
                                </div>
                            }


                            {/*MY SUBSCRIPTIONS*/}
                            <div
                                className={'avatar-row-button'}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    padding: "0.6125rem 1.25rem",
                                    paddingBottom: "0.75rem",
                                    textDecoration: "none",
                                }}
                                onClick={() => {
                                    navigate("/account")
                                }}
                            >
                                <div
                                    style={{
                                        width: "0.35rem",
                                        height: "0.35rem",
                                        position: "fixed",
                                        borderRadius: "50%",
                                        transform: "translateX(-0.75rem)",
                                        backgroundColor: strings.colors.actionNeutral,
                                        display: window.location.pathname === "/account" ? "" : "none",
                                    }}
                                >
                                </div>

                                <div
                                    className={'material-icons'}
                                    style={{
                                        opacity: "1",
                                        color: strings.colors.textTertiary,
                                        fontSize: "1.375rem",
                                        lineHeight: "1.375rem",
                                        textDecoration: "none",
                                        WebkitTouchCallout: "none",
                                        WebkitUserSelect: "none",
                                        htmlUserSelect: "none",
                                        MozUserSelect: "none",
                                        msUserSelect: "none",
                                        userSelect: "none"
                                    }}
                                >
                                    account_balance_wallet
                                </div>

                                <div
                                    style={{
                                        fontSize: "0.875rem",
                                        fontWeight: "400",
                                        textAlign: "left",
                                        paddingLeft: "1.25rem",
                                        textDecoration: "none",
                                        WebkitTouchCallout: "none",
                                        WebkitUserSelect: "none",
                                        htmlUserSelect: "none",
                                        MozUserSelect: "none",
                                        msUserSelect: "none",
                                        userSelect: "none"
                                    }}
                                >
                                    My Subscriptions
                                </div>
                            </div>

                            {/*MY PRODUCTS*/}
                            <div
                                className={'avatar-row-button'}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    padding: "0.6125rem 1.25rem",
                                    paddingBottom: "0.75rem",
                                    textDecoration: "none",
                                }}
                                onClick={() => {
                                    window.location.href = "https://auth.stratbox.imagedreality.com"
                                }}
                            >
                                <div
                                    className={'material-icons'}
                                    style={{
                                        opacity: "1",
                                        color: strings.colors.textTertiary,
                                        fontSize: "1.375rem",
                                        lineHeight: "1.375rem",
                                        textDecoration: "none",
                                        WebkitTouchCallout: "none",
                                        WebkitUserSelect: "none",
                                        htmlUserSelect: "none",
                                        MozUserSelect: "none",
                                        msUserSelect: "none",
                                        userSelect: "none"
                                    }}
                                >
                                    inventory
                                </div>

                                <div
                                    style={{
                                        fontSize: "0.875rem",
                                        fontWeight: "400",
                                        textAlign: "left",
                                        paddingLeft: "1.25rem",
                                        textDecoration: "none",
                                        WebkitTouchCallout: "none",
                                        WebkitUserSelect: "none",
                                        htmlUserSelect: "none",
                                        MozUserSelect: "none",
                                        msUserSelect: "none",
                                        userSelect: "none"
                                    }}
                                >
                                    My Products
                                </div>
                            </div>

                            {/*LOG OUT*/}
                            <div
                                className={'avatar-row-button'}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    padding: "0.6125rem 1.25rem",
                                    paddingBottom: "0.75rem",
                                    textDecoration: "none",
                                }}
                                onClick={() => {

                                    try {
                                        logout().then(result => {
                                            console.log(`logout result: ${result}`);
                                            setTimeout(window.location.href = "https://store.imagedreality.com", 2500);
                                        });
                                    }
                                    catch (e) {
                                        console.error(`Error while signing out: ${e}`);
                                    }

                                }}
                            >

                                <div
                                    className={'material-icons'}
                                    style={{
                                        opacity: "1",
                                        color: strings.colors.textTertiary,
                                        fontSize: "1.375rem",
                                        lineHeight: "1.375rem",
                                        textDecoration: "none",
                                        WebkitTouchCallout: "none",
                                        WebkitUserSelect: "none",
                                        htmlUserSelect: "none",
                                        MozUserSelect: "none",
                                        msUserSelect: "none",
                                        userSelect: "none"
                                    }}
                                >
                                    logout
                                </div>

                                <div
                                    style={{
                                        fontSize: "0.875rem",
                                        fontWeight: "400",
                                        textAlign: "left",
                                        paddingLeft: "1.25rem",
                                        textDecoration: "none",
                                        WebkitTouchCallout: "none",
                                        WebkitUserSelect: "none",
                                        htmlUserSelect: "none",
                                        MozUserSelect: "none",
                                        msUserSelect: "none",
                                        userSelect: "none"
                                    }}
                                >
                                    Log out
                                </div>
                            </div>
                        </div>
                        )
                        : null
                    }

                </div>
            }

            open={props.avatarMenuOpen}
            styles={{

                // DEFAULTS:
                root: {
                    position: undefined,
                    right: 0,
                    bottom: 0,
                    overflow: "hidden",
                },

                sidebar: {
                    position: "fixed",
                    background: "#F0F0F2",
                    width: "18rem",
                    height: (props.userDetails === undefined || props.userDetails === null)? `8.85rem` : props.userDetails.role === "admin"? `14.25rem` : `11.65rem`,
                    zIndex: "2",
                    marginTop: "0px",
                    borderRadius: "0.25rem",
                    backgroundColor: strings.colors.backgroundPrimary
                },

                content: {
                    position: undefined,
                    right: 0,
                    bottom: 0,
                    overflowY: "auto",
                    WebkitOverflowScrolling: "touch",
                    transition: "left .3s ease-out, right .3s ease-out",
                },
                overlay: {
                    zIndex: "1",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    opacity: 0,
                    visibility: "hidden",
                    transition: "opacity .3s ease-out, visibility .3s ease-out",
                    backgroundColor: "rgba(0,0,0,0.15)",
                    marginTop: "3rem",
                },
                dragHandle: {
                    zIndex: 1,
                    position: "fixed",
                    top: 0,
                    bottom: 0
                }
            }}
            pullRight={true}
            defaultSidebarWidth={500}
            docked={false}
            touch={true}
            overlayClassName={"avatarOverlay"}
            onSetOpen={onSetOpen}
        >
            <></>
        </Sidebar>
    );
}

export default AvatarMenu;
