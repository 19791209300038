
export async function createCheckoutSession(line_items: Array<any>, userEmail: string, customerType: string) {
    return await fetch(
        "https://api.analytics.imagedreality.com/store/create-checkout-session", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                line_items: line_items,
                customer_email: userEmail,
                customer_type: customerType,
            }),
            credentials: 'include'
        }
    );
}


export async function getOfferings() {
    return await fetch(
        "https://api.analytics.imagedreality.com/store/get-offers", {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        }
    );
}

export async function getSubscriptions(userEmail: string) {
    return await fetch(
        "https://api.analytics.imagedreality.com/store/get-subscriptions", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                customer_email: userEmail
            }),
            credentials: 'include'
        }
    );
}


export async function confirmCancelSubscription(userEmail: string, subscription_id: string, verification_code: string) {
    return await fetch(
        "https://api.analytics.imagedreality.com/store/cancel-subscription", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                customer_email: userEmail,
                subscription_id: subscription_id,
                verification_code: verification_code,
            }),
            credentials: 'include'
        }
    );
}

export async function requestCancel(userEmail: string, subscription_id: string, product_name: string) {
    return await fetch(
        "https://api.analytics.imagedreality.com/store/request-cancellation", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                customer_email: userEmail,
                subscription_id: subscription_id,
                product_name: product_name,
            }),
            credentials: 'include'
        }
    );
}

