// © Imaged Reality Ltd 2022. All Rights Reserved

import React, {useRef, useState} from "react";
import strings from '../strings.json';
import Modal from 'react-modal';
import { requestCancel} from "../shared/merchant";
import { useNavigate } from "react-router-dom";


document && document.getElementById('#app') && Modal.setAppElement('#app');

const customStyles = {
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },

};


const SubscriptionCard = (props) => {

    console.log(props.subscriptionItem);

    const navigate = useNavigate();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [isCanceling, setIsCanceling] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [cancellationSuccess, setCancellationSuccess] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    async function handleCancelSubscription() {

        try {
            setIsCanceling(true);
            const response = await requestCancel(props.userDetails.email, props.subscription.id, props.offer.name);

            await response.json().then(result => {

                console.log(result);

                if (!result.error) {

                    setIsCanceling(false);

                    setFeedbackMessage(result.message);

                    setCancellationSuccess(true);

                    setTimeout(() => {
                        setFeedbackMessage("");

                        console.log("subscription_card.js redirect to /account")

                        navigate("/account");
                    }, 10000);
                }

                else {

                    setIsCanceling(false);
                    setFeedbackMessage(result.error);

                }
            });
        }

        catch (e) {
            console.log(`Failed to cancel subscription:  ${e}`);

            setIsCanceling(false);

            if (e.toString() === "TypeError: Failed to fetch") {
                // feedbackMessage.current = "There was a problem canceling your subscription. Please try again, or reach out to support@imagedreality.com";
                setFeedbackMessage("There was a problem canceling your subscription. Please try again, or reach out to billing@imagedreality.com");
            }
            else {
                // feedbackMessage.current = e.toString();
                setFeedbackMessage(e.toString());
            }

            setTimeout(() => {
                setFeedbackMessage("");
            },10000)
        }

    }



    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "14rem",
                height: "23rem",
                backgroundColor: strings.colors.backgroundPrimary,
                border: `solid 0.0675rem ${strings.colors.backgroundTertiary}`,
                borderRadius: "0.25rem",
                margin: "0.5rem",
                padding: "0.75rem 1.5rem",
            }}
        >

            <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                contentLabel="Cancel subscription"
                style={{
                    ...customStyles,
                    display: "flex",
                    width: "24rem",
                    height: "16rem",
                    border: `solid 0.125rem ${strings.colors.backgroundTertiary}`,
                    backgroundColor: strings.colors.backgroundSecondary,
                    justifyContent: "center",
                }}
            >

                <div
                    style={{
                        fontSize: strings.sizes.textPrimary,
                        color: strings.colors.textPrimary,
                        fontWeight: strings.weights.medium,
                        userSelect: "none",
                        textAlign: "center",
                    }}
                >
                    {cancellationSuccess? "Your request has been submitted" : "Are you sure you want to end your subscription?"}
                </div>

                <div
                    style={{
                        fontSize: strings.sizes.textPrimary,
                        color: strings.colors.textPrimary,
                        fontWeight: strings.weights.regular,
                        marginTop: "1rem",
                        userSelect: "none",
                        textAlign: "center",
                    }}
                >
                    {cancellationSuccess? "Please check your email to confirm the cancellation." : `Your product access will continue until: ${new Date(props.current_period_end * 1000).toLocaleDateString()}`}
                </div>

                <div
                    style={{
                        fontSize: strings.sizes.textPrimary,
                        color: strings.colors.actionNegative,
                        fontWeight: strings.weights.medium,
                        marginTop: "2rem",
                        maxWidth: "18rem",
                        margin: "auto",
                        textAlign: "center",
                        userSelect: "none",
                        width: "100%",
                    }}
                >
                    {feedbackMessage}
                </div>

                {cancellationSuccess?

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            marginTop: "2rem",
                        }}
                    >
                        <div
                            style={{
                                width: "8rem",
                                height: "2rem",
                                backgroundColor: strings.colors.actionNeutral,
                                borderRadius: "0.25rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                color: strings.colors.backgroundPrimary,
                                userSelect: "none",
                            }}
                            onClick={() => {
                                closeModal();
                                navigate("/plans");
                            }}
                        >
                            <span>Dismiss</span>
                        </div>
                    </div>

                    :

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            marginTop: "2rem",
                        }}
                    >
                        <div
                            style={{
                                width: "8rem",
                                height: "2rem",
                                backgroundColor: strings.colors.actionNeutral,
                                borderRadius: "0.25rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                color: strings.colors.backgroundPrimary,
                                userSelect: "none",
                            }}
                            onClick={() => {
                                handleCancelSubscription();
                            }}
                        >
                            {isCanceling?
                                <div className="spinner-border spinner-border-sm text-light" role="status">
                                </div>
                                : <span>Confirm</span>
                            }
                        </div>
                        <div
                            style={{
                                width: "8rem",
                                height: "2rem",
                                backgroundColor: strings.colors.backgroundTertiary,
                                borderRadius: "0.25rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                color: strings.colors.textPrimary,
                                userSelect: "none",
                                opacity: isCanceling? "0.5" : "1.0",
                            }}
                            onClick={() => {

                                if (isCanceling) {
                                    return;
                                }
                                else {
                                    setIsCanceling(false);
                                    closeModal();
                                }
                            }}
                        >
                            Cancel
                        </div>
                    </div>
                }
            </Modal>

            <div
                style={{
                    width: "100%",
                }}
            >
                <img
                    src={props.offer.images[0]}
                    style={{
                        width: "auto",
                        height: "2.5rem",
                    }}
                    alt={"product image"}
                />
            </div>

            <div
                style={{
                    fontSize: strings.sizes.iconSecondary,
                    fontWeight: strings.weights.medium,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "100%",
                    marginTop: "0.5rem",
                    userSelect: "none",
                }}
            >
                {props.offer.name}
            </div>

            <div
                style={{
                    fontSize: strings.sizes.textSecondary,
                    fontWeight: strings.weights.regular,
                    width: "100%",
                    marginTop: "0.5rem",
                    userSelect: "none",
                }}
            >
                {props.offer.description}
            </div>

            <div
                style={{
                    marginTop: "1rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "80%",
                }}
            >
                <div
                    style={{
                        flexGrow: "2",
                        fontSize: "1.35rem",
                        fontWeight: strings.weights.medium,
                        userSelect: "none",
                    }}
                >
                    ${props.subscription.plan.amount / 100}.00
                </div>

                <div
                    style={{
                        fontSize: strings.sizes.textTertiary,
                        color: strings.colors.textPrimary,
                        whiteSpace: "pre-line",
                        lineHeight: strings.sizes.textTertiary,
                        fontWeight: strings.weights.medium,
                        marginLeft: "0.5rem",
                        textAlign: "center",
                        userSelect: "none",
                    }}
                >
                    {`per \n${props.subscription.plan.interval}`}
                </div>

            </div>

            {/*Seat count:*/}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "100%",
                    alignItems: "center",
                    marginTop: "1rem",
                }}
            >
                <div
                    style={{
                        fontSize: strings.sizes.textPrimary,
                        fontWeight: strings.weights.medium,
                        color: strings.colors.textPrimary,
                        userSelect: "none",
                    }}
                >
                    Seats:
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            width: "1rem",
                            textAlign: "center",
                            fontSize: strings.sizes.textSecondary,
                            fontWeight: strings.weights.medium,
                            color: strings.colors.textPrimary,
                            userSelect: "none",
                        }}
                    >
                        {props.subscription.quantity}
                    </div>
                </div>
            </div>

            {/*Next billing date:*/}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    marginTop: "1rem",
                }}
            >
                <div
                    style={{
                        fontSize: strings.sizes.textPrimary,
                        fontWeight: strings.weights.medium,
                        color: strings.colors.textPrimary,
                        width: "60%",
                        userSelect: "none",
                    }}
                >
                    Next bill due:
                </div>

                <div
                    style={{
                        textAlign: "left",
                        fontSize: strings.sizes.textSecondary,
                        fontWeight: strings.weights.medium,
                        color: strings.colors.textPrimary,
                        width: "40%",
                    }}
                >
                    {new Date(props.current_period_end * 1000).toLocaleDateString()}
                </div>

            </div>

            {/*Cancel button:*/}
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1.5rem",
                }}
            >
                <a
                    style={{
                        display: "flex",
                        width: "9rem",
                        fontSize: strings.sizes.textTertiary,
                        fontWeight: strings.weights.medium,
                        textDecoration: "underline",
                        textAlign: "center",
                        opacity: "0.7",
                        justifyContent: "center",
                        borderRadius: "0.25rem",
                        cursor: "pointer",
                        padding: "0.35rem 1rem",
                        userSelect: "none",
                        justifySelf: "flex-end",
                        alignSelf: "flex-end",
                    }}
                    onClick={() => {
                        openModal();
                    }}
                >
                    Cancel subscription
                </a>
            </div>
        </div>
    )
}

export default SubscriptionCard;