import {BrowserRouter as Router, Route, Routes, Navigate, useNavigate} from "react-router-dom";
import Header from "./components/Header";
import Cart from "./components/Cart";
import Login from "./pages/login";
import Signup from "./pages/signup";
import SignupSuccess from "./pages/signup_success";
import Verify from "./pages/verify";
import Plans from "./pages/plans";
import Checkout from "./pages/checkout";
import Return from "./pages/return";
import Account from "./pages/account";
import Cancel from "./pages/cancel";

import React, { useEffect, useState } from "react";
import { getUser } from "./shared/authentication";

import edu_domains from './edu_domains.json';
import { checkCart } from "./shared/cart";
import Footer from "./components/Footer";
import ProductPage from "./components/ProductPage";
import {getOfferings} from "./shared/merchant";


export interface product {
    id: string,
    object: string,
    active: boolean,
    attributes: any[],
    created: number,
    default_price: string,
    description: string,
    features: any[],
    images: string[],
    livemode: boolean,
    metadata: metadata,
    name: string,
    package_dimensions: any,
    shippable: any,
    statement_descriptor: any,
    tax_code: string,
    type: string,
    unit_label: any,
    updated: number,
    url: any
    prices: price[];
}

export interface price {
    id: string,
    object: string,
    active: boolean,
    billing_scheme: string,
    created: number,
    currency: string,
    custom_unit_amount: any,
    livemode: boolean,
    lookup_key: any,
    metadata: object,
    nickname: any,
    product: any,
    recurring: {
        aggregate_usage: any,
        interval: any,
        interval_count: number,
        trial_period_days: any,
        usage_type: string
    },
    tax_behavior: string,
    tiers_mode: any,
    transform_quantity: any,
    type: string,
    unit_amount: number,
    unit_amount_decimal: string
}

export interface metadata {
  type: string
}

const App = () => {

    const [loading, setLoading] = useState(false);
    const [checkAuthResult, setCheckAuthResult] = useState<boolean>(false);
    const [userDetails, setUserDetails] = useState<any | null>();
    const [avatarMenuOpen, setAvatarMenuOpen] = useState(false);
    const [isEdu, setIsEdu] = useState(false);
    const [cartOpen, setCartOpen] = useState(false);
    const [offers, setOfferings] = useState<product[]>([]);
    const location = window.location.pathname;

    useEffect(() => {
        setLoading(true);

        try {
          getOfferings().then((res) => (res.json())
            .then((data) => {
              console.log(data);
              setOfferings(data.offerings);
            })
          );

          console.log(offers);
        }
        catch (e) {
          console.log("ERROR Fetching offerings");
          console.log(e);

          setOfferings([]);
        }

        getUser().then(({error, payload}) => {

            console.log("checkAuthResult:");
            console.log(error);
            console.log(payload);
            setLoading(false);

            if (error !== null) {
                setCheckAuthResult(false);
            }
            else {
                setUserDetails(payload.payload);
                setCheckAuthResult(true);

                // console.log(payload.payload.email.split("@")[1])

                console.log(`The current path is: ${location}`);

                // Check if the user has a valid edu email:
                if (edu_domains.includes(payload.payload.email.split("@")[1])) {
                    console.log(`${payload.payload.email.split("@")[1]} is a valid edu domain`);
                    setIsEdu(true);
                }

                // Remove any Academia products from the cart, and notify user:
                else if (location !== "/verify") {
                    console.log("*");
                    checkCart();
                }
            }
        });
    }, []);


    useEffect(() => {

        console.log(userDetails);

        if (userDetails !== undefined && edu_domains.includes(userDetails.email.split("@")[1])) {
            console.log(`${userDetails.email.split("@")[1]} is a valid edu domain`);
            setIsEdu(true);
        }
        else if (userDetails !== undefined && location !== "/verify") {
            console.log("**");
            checkCart();
        }

    }, [userDetails]);



    return (

        <div
            id={"app"}
            className={"app"}
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                // overflow: "scroll",
                width: cartOpen? `calc(${window.innerWidth}px - 22rem)` : "100%",
                cursor: cartOpen? "pointer" : "default",
            }}
            // onClick={() => {
            //   console.log("setexpanded");
            //   expanded && setExpanded(false)
            // }}
        >

            <Router>
                <Header
                    userDetails={userDetails}
                    cartOpen={cartOpen}
                    setCartOpen={setCartOpen}
                    avatarMenuOpen={avatarMenuOpen}
                    setAvatarMenuOpen={setAvatarMenuOpen}
                    loading={loading}
                />

                <Cart
                    setAvatarMenuOpen={setAvatarMenuOpen}
                    userDetails={userDetails}
                    isEdu={isEdu}
                    setCartOpen={setCartOpen}
                    cartOpen={cartOpen}
                />

                <Routes>
                    <Route
                        path="/"
                        element={
                            <Navigate
                                to="/plans"
                                replace
                            />
                        }
                    />

                    <Route
                        path="/login"
                        element={
                            <Login
                                setUserDetails={setUserDetails}
                                userDetails={userDetails}
                                isEdu={isEdu}
                            />
                        }
                    />
                    <Route
                        path="/signup"
                        element={<Signup/>}
                    />

                    <Route
                        path="/signup_success"
                        element={<SignupSuccess/>}
                    />

                    <Route
                        path="/verify"
                        element={
                            <Verify
                                setUserDetails={setUserDetails}
                            />
                        }
                    />

                    <Route
                        path="/cancel"
                        element={
                            <Cancel
                                setUserDetails={setUserDetails}
                            />
                        }
                    />

                    <Route
                        path="/plans"
                        element={
                            <Plans
                                isEdu={isEdu}
                                userDetails={userDetails}
                                offers={offers}
                                setOfferings={setOfferings}
                                setCartOpen={setCartOpen}
                                cartOpen={cartOpen}
                            />
                        }
                    />

                    <Route
                      path="/Stratbox Core Explorer"
                      element={
                          <ProductPage
                            isEdu={isEdu}
                            userDetails={userDetails}
                            offers={offers}
                            setOfferings={setOfferings}
                            setCartOpen={setCartOpen}
                            cartOpen={cartOpen}
                            productName={"Stratbox Core Explorer"}
                          />
                      }
                    />

                    <Route
                      path="/Stratbox Connect"
                      element={
                          <ProductPage
                            isEdu={isEdu}
                            userDetails={userDetails}
                            offers={offers}
                            setOfferings={setOfferings}
                            setCartOpen={setCartOpen}
                            cartOpen={cartOpen}
                            productName={"Stratbox Connect"}
                          />
                      }
                    />

                    <Route
                      path="/Stratbox Connect VR"
                      element={
                          <ProductPage
                            isEdu={isEdu}
                            userDetails={userDetails}
                            offers={offers}
                            setOfferings={setOfferings}
                            setCartOpen={setCartOpen}
                            cartOpen={cartOpen}
                            productName={"Stratbox Connect VR"}
                          />
                      }
                    />

                    <Route
                      path="/Stratbox Analytics"
                      element={
                          <ProductPage
                            isEdu={isEdu}
                            userDetails={userDetails}
                            offers={offers}
                            setOfferings={setOfferings}
                            setCartOpen={setCartOpen}
                            cartOpen={cartOpen}
                            productName={"Stratbox Analytics"}
                          />
                      }
                    />

                    <Route
                      path="/Stratbox Suite"
                      element={
                          <ProductPage
                            isEdu={isEdu}
                            userDetails={userDetails}
                            offers={offers}
                            setOfferings={setOfferings}
                            setCartOpen={setCartOpen}
                            cartOpen={cartOpen}
                            productName={"Stratbox Suite: Connect + VR + Analytics + Core Explorer"}
                          />
                      }
                    />

                    <Route
                      path="/Stratbox Premium"
                      element={
                          <ProductPage
                            isEdu={isEdu}
                            userDetails={userDetails}
                            offers={offers}
                            setOfferings={setOfferings}
                            setCartOpen={setCartOpen}
                            cartOpen={cartOpen}
                            productName={"Stratbox Premium: Connect + VR + Analytics"}
                          />
                      }
                    />
                    <Route
                        path="/checkout"
                        element={

                            <Checkout
                                userDetails={userDetails}
                                isEdu={isEdu}
                            />

                        }
                    />
                    <Route
                        path="/return"
                        element={<Return/>}
                    />

                    <Route
                        path="/account"
                        element={
                            <Account
                                userDetails={userDetails}
                                setUserDetails={setUserDetails}
                                setIsEdu={setIsEdu}
                                setCheckAuthResult={setCheckAuthResult}
                            />
                        }
                    />
                </Routes>

                <Footer/>
            </Router>
        </div>
    )

}

export default App;