import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import React from "react";
import { confirmCancelSubscription} from "../shared/merchant";
import Cookies from "js-cookie";
function Cancel (props: any){

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [cancelData, setCancelData] = React.useState(
        {
            isCanceling: false,
            cancelFetched: false,
            cancelVerified: false,
            feedbackMessage: ""
        }
    );

    useEffect(() => {

        props.setUserDetails(undefined);

        console.log(searchParams.get('email') || "no email");
        console.log(searchParams.get('verification_code') || "no verification_code");
        console.log(searchParams.get('subscription_id') || "no subscription_id");

        if(cancelData.cancelVerified) {
            console.log("redirecting to login page");
            setTimeout(() => {
                console.log("cancel.tsx redirect to /login")
                navigate("/login?cart=false");
            }, 3500);
        }
        else if(cancelData.isCanceling) {
            console.log("calling fetch canceling");
            cancelSubscription();
        }
    });

    function renderCanceling(){
        return(
            <form className="form-cancel" onSubmit={handleCancel}>
                <h2
                    style={{textAlign: "center"}}
                >Confirm cancellation</h2>


                &nbsp;
                <p
                    style={{textAlign: "center"}}
                >Please confirm the request cancel your subscription to:</p>

                <div
                    style={{
                        textAlign: "center",
                    }}
                >
                    <b>{searchParams.get('product')}</b>
                </div>
                &nbsp;
                <button id="cancel" className="btn btn-stbx-primary form-control">
                    Confirm
                </button>
            </form>)
    }

    function handleCancel(event: React.FormEvent<HTMLFormElement>){

        // try {
        //     document.cookie = 'id_token' + '=; Path=/; Domain=.imagedreality.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        //     document.cookie = 'access_token' + '=; Path=/; Domain=.imagedreality.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        //     document.cookie = 'refresh_token' + '=; Path=/; Domain=.imagedreality.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        //     document.cookie = 'CloudFront-Signature' + '=; Path=/; Domain=.imagedreality.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        //     document.cookie = 'CloudFront-Policy' + '=; Path=/; Domain=.imagedreality.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        //     document.cookie = 'CloudFront-Key-Pair-Id' + '=; Path=/; Domain=.imagedreality.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        // }
        // catch (e) {
        //     console.log("Unable to clear all cookie values...");
        //     console.log(e);
        // }

        event.preventDefault();
        console.log("canceling");
        setCancelData(prevCancelData=>{
            return {
                ...prevCancelData,
                isCanceling:true
            }
        });
    }

    async function cancelSubscription(){
        var email = searchParams.get('email') || "";
        var verification_code = searchParams.get('verification_code') || "";
        var subscription_id = searchParams.get('subscription_id') || "";

        if (email && verification_code && subscription_id) {

            const response = await confirmCancelSubscription(email, subscription_id, verification_code);
            await response.json().then(result => {

                console.log(result);

                if (result.error) {

                    setCancelData(prevCancelData => {
                        return {
                            ...prevCancelData,
                            cancelFetched: true,
                            cancelVerified: false,
                            isCanceling: false,
                            feedbackMessage: result.error
                        }
                    });
                }

                else {
                    setCancelData(prevCancelData => {
                        return {
                            ...prevCancelData,
                            cancelFetched: true,
                            cancelVerified: true,
                            isCanceling: false,
                            feedbackMessage: "Your subscription has been cancelled. We'll redirect you to the login page shortly."
                        }
                    });
                }
            });
        }
        else {
            setCancelData(prevCancelData=>{
                return {
                    ...prevCancelData,
                    cancelFetched:true,
                    cancelVerified:false,
                    isCanceling:false,
                    feedbackMessage : "Bad or missing parameters."
                }
            });
        }
    }

    return(
        <div
            className="sc-login p-4"
            style={{marginTop: "4rem"}}
        >
            {cancelData.cancelFetched ?
                <div
                    className="cancel-title"
                >
                    {cancelData.feedbackMessage}
                </div>
                : cancelData.isCanceling ?
                    <div
                        className="cancel-title"
                    >
                        Cancelling...
                    </div>
                    : renderCanceling()
            }
        </div>
    );
}

export default Cancel;