// © Imaged Reality Ltd 2022. All Rights Reserved
// import stbxLogo from '../img/stratbox_logo.png';

import AvatarMenu from "./AvatarMenu";
import strings from "../strings.json";
import React, { useEffect, useState } from "react";
import { getUser } from "../shared/authentication";
import {Navigate, useNavigate} from "react-router-dom";
const Header = (props: any) => {

    // const [avatarMenuOpen, setAvatarMenuOpen] = useState(false);
    const navigate = useNavigate();

    return(

      <div
        style={{
          width: "100%",
          minHeight: "3rem",
          // border: "solid 1px fuchsia",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 0 0 1rem",
          backgroundColor: strings.colors.backgroundPrimary
        }}
      >

        <div
          style={{
            width: "25rem",
            flexShrink: "1",
            display: "flex",
          }}
        >
          <img
            src={"stratbox_logo.png"}
            style={{
              height: "1.75rem",
              cursor: "pointer",
            }}
            onClick={ () => {

              console.log("setexpanded");
              props.setCartOpen(false);
              window.location.href = "https://imagedreality.com";
            }}
        />

        </div>

        <div
          style={{
            fontSize: strings.sizes.iconSecondary,
            fontWeight: strings.weights.medium,
            color: strings.colors.textPrimary,
            alignSelf: "center",
            width: "25rem",
            textAlign: "center",
            flexShrink: "1",
            cursor: "pointer"
          }}
          onClick={() => {
              navigate("/plans", {replace: true} )
          }}
        >
          Store
        </div>


        {/* AVATAR / MENU: */}

        <div
          style={{
            color: "#f4f4f4",
            paddingRight: "4.375rem",
            display: "flex",
            flexShrink: "1",
            height: "100%",
            alignItems: "center",
            justifySelf: "flex-end",
            justifyContent: "flex-end",
            cursor: "pointer",
            width: "25rem",
            textOverflow: "ellipsis",
            flexWrap: "nowrap",
            // backgroundColor: strings.colors.backgroundTertiary
          }}

        >

          {props.loading?
            <div
              style={{
                // backgroundColor: strings.colors.actionNeutral,
                height: "3rem",
                display: "flex",
                alignItems: "center",
                // width: "2rem",
                // color: strings.colors.actionNeutral,
                // paddingRight: "4.375rem",
              }}
            >
              <div
                className="spinner"
              />
            </div>
            :

              <div
                className={`material-icons`}
                style={{
                  color: strings.colors.textPrimary,
                  fontSize: strings.sizes.iconPrimary,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  cursor: "pointer",
                  height: "3rem",
                  // backgroundColor: "rgba(51,51,51,0.2)"
                }}
                onClick={() => {
                 if (props.userDetails !== undefined) {
                   props.setCartOpen(false)
                   props.setAvatarMenuOpen(!props.avatarMenuOpen)
                 }
                 else {
                   navigate("/signup", {replace: true} )
                 }
                }}
              >
                account_circle
              </div>
            }
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: strings.colors.textPrimary,
                fontSize: strings.sizes.textPrimary,
                fontWeight: strings.weights.regular,
                marginLeft: "0.5rem",
                textOverflow: "hidden",
                textWrap: "nowrap",
                textDecoration: "none",
                WebkitTouchCallout: "none",
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                msUserSelect: "none",
                userSelect: "none",
                height: "3rem",
              }}
              onClick={() => {
                if (props.userDetails !== undefined) {
                  props.setAvatarMenuOpen(!props.avatarMenuOpen)
                  props.setCartOpen(false)
                }
                else {
                  navigate("/signup", {replace: true} )
                }
              }}
            >
                {props.loading? "" : props.userDetails !== undefined ? props.userDetails.first_name : "Create an account"}
            </div>

            <AvatarMenu
                userDetails={props.userDetails}
                avatarMenuOpen={props.avatarMenuOpen}
                setAvatarMenuOpen={props.setAvatarMenuOpen}
                setCartOpen={props.setcartOpen}
            />
        </div>

        </div>
    );
}

export default Header;